<template>
  <section class="breadcrumb-container">
    <div class="breadcrumb-list">
      <button v-for="breadcrumb in breadcrumbs" :key="breadcrumb.label" class="breadcrumb-item">
        <span
          @click="handleBreadcrumbClick(breadcrumb)"
          class="text-capitalize"
          :class="{ active: breadcrumb.active }"
        >
          {{ breadcrumb.label }}
        </span>
      </button>
    </div>
  </section>
</template>

<script setup>
const props = defineProps({
  breadcrumbs: {
    type: Array,
    required: true,
  },
  userId: {
    type: String,
    required: true,
  },
  studyId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['navigate']);

const handleBreadcrumbClick = (breadcrumb) => {
  const params = breadcrumb.param ? { [breadcrumb.param]: breadcrumb.label } : {};
  emit('navigate', breadcrumb.name, params);
};
</script>

<style lang="scss" scoped>
.breadcrumb-list {
  list-style: none;
  margin: 1.5rem 0;
}

.breadcrumb-item {
  display: inline;
  font-size: 18px;
  background-color: transparent;
  outline: none;
  border: none;
}

.breadcrumb-item + .breadcrumb-item:before {
  content: '>';
  padding: 0 8px;
  color: black;
}

.breadcrumb-item span {
  color: #6c757d;
  font-size: 28px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.5s all;

  &:hover,
  &.active {
    color: #000;
    font-weight: 700;
  }
}

@media (max-width: 790px) {
  .breadcrumb-item span {
    font-size: 18px;
  }
}
</style>
