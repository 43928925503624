<template>
  <component
    v-if="selectedChartComponent"
    :is="selectedChartComponent"
    :series="series"
    :labels="labels"
    :hkCode="hkCode"
    :isTooltipCustom="isTooltipCustom"
  />
  <div v-else>No data found</div>
  <small class="italic"
    >Please note that if the chart is not visible, you may need to adjust the date range.</small
  >
</template>

<script setup>
import { computed } from 'vue';
import LineChart from '@/components/apexCharts/LineChart.vue';
import ScatterChart from '@/components/apexCharts/ScatterChart.vue';
import RangeChart from '@/components/apexCharts/RangeChart.vue';
import BarChart from '@/components/apexCharts/BarChart.vue';
import { GRAPH_TYPES } from '../utils/HealthKit';

const props = defineProps({
  graphType: {
    type: String,
    required: true,
  },
  series: {
    type: Array,
    required: true,
  },
  labels: {
    type: Array,
    default: () => [],
  },
  hkCode: {
    type: String,
    required: true,
  },
  isTooltipCustom: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const selectedChartComponent = computed(() => {
  const chartMap = {
    [GRAPH_TYPES.LINE]: LineChart,
    [GRAPH_TYPES.SCATTER]: ScatterChart,
    [GRAPH_TYPES.RANGE]: RangeChart,
    [GRAPH_TYPES.BAR]: BarChart,
  };

  return chartMap[props.graphType] || null;
});
</script>

<style lang="scss" scoped>
.italic {
  font-style: italic;
}
</style>
