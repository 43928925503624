export const generateCSVFile = (csvData, filePrefix) => {
  if (!csvData.length) throw new Error('No data to download');

  const formattedDate = new Date().toISOString().slice(0, 10);
  const fileName = `${filePrefix}_${formattedDate}.csv`;

  const csvContent = csvData.map((row) => row.join(',')).join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();
};
