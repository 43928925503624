<template>
  <div class="flex justify-center m-5">
    <alt-table
      :columns="columns"
      pagination
      :paginationOptions="paginationOptions"
      @onPagination="handlePagination"
    >
      <template #t-row>
        <tr v-for="(exercise, index) in getPageItems" :key="index">
          <td>{{ index + 1 }}</td>
          <td>
            <h4 class="flex justify-center">
              {{ exercise.date || 'NN' }}
            </h4>
          </td>
          <td>
            <h3 class="flex justify-center">{{ exercise.name }}</h3>
          </td>
          <td class="flex justify-center">
            <loading-icon class="flex justify-center" v-if="loadingId === exercise.id" size="5px" />
            <span
              v-else
              class="btn-ck btn-video pointer"
              @click="handleSelectExercise(exercise.id, exercise.date, exercise.exercises)"
            >
              Video + Data
            </span>
          </td>
        </tr>
      </template>
    </alt-table>
  </div>
</template>

<script setup>
import altTable from '@/components/tables/altTable';
import loadingIcon from '@/components/loading';
import { computed, ref } from 'vue';
import { downloadFolderContents } from '@/patients/services/patients';
import { useOptions } from '@/components/common/paginationOptions';
import { toast } from 'vue3-toastify';

const props = defineProps({
  columns: Array,
  patientExercisesInformation: Array,
});

const loadingId = ref('');
const currentPage = ref(1);
const limit = ref(10);

async function handleSelectExercise(id, exerciseDate, exercises) {
  try {
    loadingId.value = id;
    exercises.map(async (exercise) => {
      await downloadFolderContents(exercise.videoPath, exerciseDate, exercise.name);
    });
  } catch (error) {
    toast.error(
      `Some videos couldn't be downloaded. This might be due to browser restrictions or an API issue. Please check and try again.`
    );
  } finally {
    loadingId.value = '';
  }
}

const { paginationOptions, handlePagination } = useOptions(props.patientExercisesInformation);

const getPageItems = computed(() => {
  const items = props.patientExercisesInformation.toSorted(
    (exerciseA, exerciseB) => exerciseB.timestamp - exerciseA.timestamp
  );
  const lowerLimit = (currentPage.value - 1) * limit.value;
  const upperLimit = currentPage.value * limit.value;
  return items.slice(lowerLimit, upperLimit);
});
</script>

<style lang="scss">
h1 {
  margin-top: 25px;
  margin-bottom: 50px;

  span {
    color: #34455A;
  }
}

.survey {
  display: flex;
  justify-content: center;
}

.select {
  width: 100%;
}

.btn-video {
  border-radius: 6px;
  font-size: 12px;
}
</style>
