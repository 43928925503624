<template>
  <modal
    title="Edit Group Item"
    :maxWidth="'450px'"
    :showModal="isTogglingEditModal"
    @closeModal="$emit('toggleEditModal')"
  >
    <template v-slot:content>
      <form class="form" @submit.prevent="handleSubmitEdit">
        <section class="m-2 form-group">
          <label for="editGroupItemType" class="flex mt-3 text-muted">Item Type</label>
          <select
            class="form-select mt-1"
            id="editGroupItemType"
            v-model="temporalSelectedType"
            @change="onItemTypeChange($event.target.value)"
            required
          >
            <option v-for="itemType in questionnaireItemTypes" :value="itemType">
              {{ capitalize(itemType) }}
            </option>
          </select>

          <div
            v-if="editingItemCopy.type === 'group' || editingItemCopy.type === 'display'"
            class="mt-3"
          >
            <label for="editTextGroupItem" class="flex text-muted">Text</label>
            <input
              class="form-input mt-1 w-100"
              type="text"
              id="editTextGroupItem"
              v-model="editingItemCopy.text"
              required
            />
          </div>

          <div v-else class="mt-3">
            <label for="editQuestionGroupItem" class="flex text-muted">Question</label>
            <input
              class="form-input mt-1 w-100"
              type="text"
              id="editQuestionGroupItem"
              v-model="editingItemCopy.text"
              required
            />
          </div>
          <SwitchInput
            v-if="questionnaireItemTypesWithoutGroup.includes(editingItemCopy.type)"
            v-model="editingItemCopy.required"
            label="Answer Required:"
          />
        </section>

        <section
          v-if="questionnaireItemsWithOptions.includes(editingItemCopy.type)"
          class="m-2 form-group"
          required
        >
          <label for="answerOptions" class="flex text-muted">Answer Options (Min. 2)</label>
          <button
            class="form-input mt-1 w-100 addButton"
            type="button"
            id="answerOptions"
            @click="toggleAddOptionsModal"
          >
            Add New Answer Option <b>+</b>
          </button>

          <ul class="h-200 options-list" v-if="editingItemCopy.options?.length">
            <li
              v-for="(option, index) in editingItemCopy.options"
              :key="`option-${index}-${option}`"
            >
              <div>
                <span class="mb-2">Option {{ index + 1 }}</span>
                <p class="break-words-smaller">{{ option?.display ?? option }}</p>
              </div>
              <div class="mr-2">
                <button
                  type="button"
                  class="btn btn-ck delete-button fill-danger"
                  @click="handleRemoveItemOption(index)"
                >
                  Delete
                </button>
              </div>
            </li>
          </ul>
        </section>

        <section v-if="editingItemCopy.type === 'group'" class="mt-3">
          <label for="groupItems" class="flex text-muted">Group Items</label>
          <button
            class="form-input mt-1 w-100 addButton"
            id="groupItems"
            type="button"
            @click="toggleGroupItemsModal"
          >
            Add New Group Item <b>+</b>
          </button>
        </section>

        <ul
          class="h-200 options-list"
          v-if="editingItemCopy.type === 'group' && editingItemCopy.item?.length"
        >
          <li
            v-for="(groupItem, index) in editingItemCopy.item"
            :key="`item-${index}-${groupItem}`"
          >
            <div class="ml-2">
              <span class="flex justify-center mb-2">
                Item {{ index + 1 }} - Type {{ groupItem.type }}
              </span>
              <p class="break-words-smaller">{{ groupItem.text ?? groupItem.value }}</p>
            </div>
            <div>
              <button
                class="btn btn-ck delete-button fill-danger"
                type="button"
                @click="handleRemoveGroupItem(index)"
              >
                Delete
              </button>
            </div>
          </li>
        </ul>

        <section class="flex gap-3 mt-5 w-100">
          <button class="m-auto btn btn-ck" type="submit">Save Changes</button>
        </section>
      </form>
    </template>
  </modal>

  <modal
    title="Change Item Type"
    :maxWidth="'450px'"
    :withoutCloseOption="true"
    :showModal="isTogglingConfirmDeleteModal"
    @closeModal="toggleConfirmDeleteModal"
  >
    <template v-slot:content>
      <form class="form" @submit.stop.prevent="confirmTypeChange">
        <section class="m-2 form-group">
          Are you sure you want to change the type of the selected item? This action will remove the
          answer options
        </section>

        <section class="flex gap-3 mt-5 w-100">
          <button class="m-auto btn btn-ck" type="button" @click="cancelTypeChange">
            <strong>Cancel</strong>
          </button>
          <button class="m-auto btn btn-ck fill-danger" type="submit">
            <loading-icon
              class="flex justify-center w-100"
              :color="'white'"
              v-if="isLoadingChange"
              size="6px"
            />
            <strong v-else> Change </strong>
          </button>
        </section>
      </form>
    </template>
  </modal>

  <register-item-option
    :item="editingItemCopy"
    :isTogglingOptionsModal="isTogglingOptionsModal"
    @add-item-option="handleAddItemOption"
    @toggle-add-options-modal="toggleAddOptionsModal"
  />

  <register-group-item
    :item="newGroupItem"
    :groupItems="editingItemCopy.item"
    :isTogglingGroupItemsModal="isTogglingGroupItemsModal"
    @add-new-group-item="handleAddNewGroupItem"
    @add-group-item-option="handleAddGroupItemOption"
    @reset-new-group-item="resetNewGroupItem"
    @remove-group-option="removeGroupOption"
    @toggle-group-items-modal="toggleGroupItemsModal"
  />
</template>

<script setup>
import { ref, watch } from 'vue';
import { toast } from 'vue3-toastify';

import {
  capitalize,
  questionnaireItemTypes,
  questionnaireItemsWithOptions,
  questionnaireItemTypesWithoutGroup,
  isCodingTypeElementWithAtLeastTwoOptions,
} from '@/surveys/utils';

import Modal from '@/components/Modal';
import loadingIcon from '@/components/loading';
import RegisterItemOption from '@/surveys/atoms/RegisterItemOption';
import RegisterGroupItem from '@/surveys/atoms/RegisterGroupItem';
import SwitchInput from '@/components/SwitchInput.vue';

const props = defineProps({
  isTogglingEditModal: Boolean,
  editingItem: {
    type: Object,
    default: {},
  },
  editingIndex: Number,
  survey: {
    type: Object,
    default: {
      name: '',
      items: [],
    },
  },
});

const emit = defineEmits(['toggleEditModal', 'setNewItemType', 'submitEditSurveyItem']);

const newGroupItem = ref({
  value: '',
  type: 'text',
  required: false,
  options: [],
});

const originalType = ref('');
const selectedItemType = ref('');
const temporalSelectedType = ref('');
const editingItemCopy = ref({});

const isLoadingChange = ref(false);
const isTogglingOptionsModal = ref(false);
const isTogglingGroupItemsModal = ref(false);
const isTogglingConfirmDeleteModal = ref(false);

function toggleAddOptionsModal() {
  isTogglingOptionsModal.value = !isTogglingOptionsModal.value;
}

function toggleGroupItemsModal() {
  isTogglingGroupItemsModal.value = !isTogglingGroupItemsModal.value;
}

function toggleConfirmDeleteModal() {
  isTogglingConfirmDeleteModal.value = !isTogglingConfirmDeleteModal.value;
}

const handleAddGroupItemOption = (item) => newGroupItem.value.options.push(item);

const handleSetNewItemType = (newType) => (editingItemCopy.value.type = newType);

const handleAddItemOption = (option) => editingItemCopy.value.options.push(option);

const handleAddNewGroupItem = (item) => editingItemCopy.value?.item?.push({ ...item });

const handleRemoveGroupItem = (index) => editingItemCopy.value?.item?.splice(index, 1);

const handleRemoveItemOption = (index) => editingItemCopy.value.options.splice(index, 1);

const removeGroupOption = (index) => newGroupItem.value.options.splice(index, 1);

function resetNewGroupItem() {
  newGroupItem.value = { value: '', type: 'text', options: [] };
}

function cancelTypeChange() {
  temporalSelectedType.value = selectedItemType.value;
  toggleConfirmDeleteModal();
}

function confirmTypeChange() {
  selectedItemType.value = temporalSelectedType.value;
  originalType.value = temporalSelectedType.value;

  handleSetNewItemType(temporalSelectedType.value);
  editingItemCopy.value.options = [];
  toggleConfirmDeleteModal();
}

function onItemTypeChange(newType) {
  temporalSelectedType.value = newType;

  if (questionnaireItemsWithOptions.includes(newType)) {
    selectedItemType.value = newType;
    originalType.value = newType;

    handleSetNewItemType(newType);
    return;
  }

  if (editingItemCopy.value.options?.length > 0) toggleConfirmDeleteModal();
}

function handleSubmitEdit() {
  if (isCodingTypeElementWithAtLeastTwoOptions(editingItemCopy.value)) {
    toast.error('Add at least two options to continue');
  } else {
    emit('submitEditSurveyItem', editingItemCopy.value);
    toast.success('The item has been updated successfully');
    emit('toggleEditModal');
  }
}

watch(
  () => props.isTogglingEditModal,
  (newValue) => {
    if (newValue) {
      editingItemCopy.value = JSON.parse(JSON.stringify(props.editingItem));
      selectedItemType.value = editingItemCopy.value.type;
      originalType.value = editingItemCopy.value.type;
      temporalSelectedType.value = editingItemCopy.value.type;
    }
  }
);
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;

  .form-group {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;

    label {
      width: fit-content;
      text-align: start;
      margin-bottom: 3px;
      font-size: 10pt;
    }

    a {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  button#b-form {
    margin-top: 1rem;
  }
}

.delete-button {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #fff;
  border: none;
  margin-right: 5px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}
</style>
