const MONTHS = [
  { name: 'January', id: '0' },
  { name: 'February', id: '1' },
  { name: 'March', id: '2' },
  { name: 'April', id: '3' },
  { name: 'May', id: '4' },
  { name: 'June', id: '5' },
  { name: 'July', id: '6' },
  { name: 'August', id: '7' },
  { name: 'September', id: '8' },
  { name: 'October', id: '9' },
  { name: 'November', id: '10' },
  { name: 'December', id: '11' },
];

const getDayName = (date) => new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(date);

const generateWeekDays = () =>
  Array.from({ length: 7 }, (_, index) => getDayName(new Date(2018, 0, index + 1)));

const WEEK_DAYS = generateWeekDays();

export { MONTHS, WEEK_DAYS };
