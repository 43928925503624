<template>
  <section>
    <patient-list v-if="usersStudy.length" :patients="usersStudy" :studyId="studyId" />
  </section>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import PatientList from '@/patients/organisms/PatientOrganismList.vue';
import { getUsers } from '@/studies/services/studies';
import { toast } from 'vue3-toastify';

const route = useRoute();
const usersStudy = ref([]);
const studyId = ref(route.params.studyId);
const isLoading = ref(false);

onMounted(async () => {
  isLoading.value = true;
  try {
    const response = await getUsers({ studyId: studyId.value });
    usersStudy.value = response;
  } catch (error) {
    toast.error(`Sorry, there was an error fetching the users ${error}`);
  } finally {
    isLoading.value = false;
  }
});
</script>

<style scoped lang="scss">
.surveysBtn {
  margin: 65px;
  text-decoration: none;
  font-weight: 300;
  font-size: 20px;
  color: #000000;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: #000000;
  box-shadow: 5px 5px 5px;
}
</style>
