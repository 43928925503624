import request from '@/Rest';
import { storage } from '@/plugins/firebase/firebase';
import { usersPaths, surveysPaths, exercisesPaths } from '@/common/static_data/api_routes';
import { SURVEYS_IDS } from '@/common/static_data/constants';
import JSZip from 'jszip';

export async function getPatientData({ studyId, patientId }) {
  try {
    const patientTasks = await request.GET(usersPaths.completedTasks(patientId)).Execute();
    const patientTasksData = patientTasks.docs.map((task) => ({ ...task.data(), id: task.id }));

    if (patientTasksData.length > 0) {
      const patientTasksGrouped = patientTasksData
        .filter(
          (task) =>
            task.studyReference?.id === studyId ||
            task.studyReference?.id === SURVEYS_IDS.ON_BOARDING_SURVEY
        )
        .reduce(
          (acc, task) => {
            if ('exerciseVideoPath' in task) {
              acc['exercises'].push(task);
            } else {
              acc['surveys'].push(task);
            }
            return acc;
          },
          { exercises: [], surveys: [] }
        );
      return patientTasksGrouped;
    }
    return {
      exercises: [],
      surveys: [],
    };
  } catch (error) {
    throw new Error(error);
  }
}

export async function downloadExerciseVideo(pathFile, exerciseDate) {
  try {
    const ref = storage.ref();
    const pathReference = ref.child(pathFile);
    const listFilesReferences = await pathReference.listAll();
    const mp4ReferencesNamesFiltered = listFilesReferences.items.filter((_ref) =>
      _ref.name.includes('.mp4')
    );
    const videoReferences = mp4ReferencesNamesFiltered.map((_ref) =>
      ref.child(`${pathFile}/${_ref.name}`)
    );

    videoReferences.map((reference, index) =>
      reference.getDownloadURL().then((url) => {
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = () => {
          const blob = xhr.response;

          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `Exercise_${index + 1}_on_${exerciseDate}.mp4`;
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
        };

        xhr.open('GET', url);
        xhr.send();
      })
    );
  } catch (error) {
    throw new Error(error);
  }
}

export async function downloadFolderContents(pathFile, exerciseDate, exerciseName) {
  try {
    const ref = storage.ref();
    const zip = new JSZip();
    const nameReport = `report_${exerciseDate}_${exerciseName}`;
    const pathReference = ref.child(pathFile);
    const listFilesReferences = await pathReference.listAll();

    const filePromises = listFilesReferences.items.map(async (_ref, index) => {
      const fileRef = ref.child(`${pathFile}/${_ref.name}`);
      const url = await fileRef.getDownloadURL();
      const response = await fetch(url);
      const blob = await response.blob();
      zip.file(_ref.name, blob);
    });

    await Promise.all(filePromises);

    const content = await zip.generateAsync({ type: 'blob' });
    const blobURL = URL.createObjectURL(content);
    const link = document.createElement('a');
    link.href = blobURL;
    link.download = `${nameReport}.zip`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(blobURL);
  } catch (error) {
    console.error('Error downloading folder contents:', error);
    throw new Error(error);
  }
}

export async function getSurveys() {
  try {
    const data = await request.GET(surveysPaths.list).Execute();
    const surveys = data.docs.map((element) => ({
      ...element.data(),
      id: element.id,
    }));
    return surveys;
  } catch (error) {
    throw new Error('Error while fetching surveys');
  }
}

export const getExercises = async () => {
  try {
    const data = await request.GET(exercisesPaths.list).Execute();
    const exercises = data.docs.map((element) => ({
      ...element.data(),
      id: element.id,
    }));
    return exercises;
  } catch (error) {
    throw new Error(`Sorry, there was an error fetching the exercises ${error}`);
  }
};
