<template>
  <section class="flex justify-center w-100">
    <div class="top flex tooltip">
      <slot name="tooltip-trigger">Posa tu ratón sobre mí</slot>

      <div class="tiptext">
        <slot name="tooltip-content">Texto del tooltip</slot>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.tooltip {
  position: relative;
  cursor: pointer;
}

.tooltip .tiptext {
  visibility: hidden;
  opacity: 0;
  width: 250px;
  background-color: #34455A;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding: 8px 5px;
  position: absolute;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.tooltip .tiptext::after {
  content: "";
  position: absolute;
  border-width: 5px;
  border-style: solid;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tiptext {
  visibility: visible;
  opacity: 1;
}
</style>