<template>
  <div class="loading" v-if="loading">
    <div class="loader">
      <loading-icon class="flex justify-center" size="12px" />
      <h2>Loading</h2>
    </div>
  </div>
  <div class="page" v-else>
    <div class="m-2">
      <h2>
        <span>Patient - </span><small>{{ patientName }}</small>
      </h2>
      <h2>
        <span>Identifier - </span><small>{{ patientId }}</small>
      </h2>
    </div>
    <div v-if="hasPatientData">
      <div class="grid">
        <div>
          <h2 class="flex justify-center mt-2">Surveys</h2>
          <surveys-table
            v-if="patientSurveysInformation.length !== 0"
            :columns="columnsSurveysList"
            :patientSurveysInformation="patientSurveysInformation"
            :patientName="patientName"
            :patientId="patientId"
          />
          <h3 class="m-5" v-else>Without surveys...</h3>
        </div>
        <div>
          <h2 class="flex justify-center mt-2">Exercises</h2>
          <exercises-table
            v-if="patientExercisesInformation.length !== 0"
            :columns="columnsExercisesList"
            :patientExercisesInformation="patientExercisesInformation"
          />
          <h3 class="m-5" v-else>Without Exercises...</h3>
        </div>
      </div>
    </div>
    <div class="flex justify-center" v-else>
      <h2>Without patient data...</h2>
    </div>
    <HealthCategories />
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { computed, onMounted, ref } from 'vue';
import { toast } from 'vue3-toastify';
import { getPatientData, getSurveys } from '@/patients/services/patients';
import { formatDateString } from '../utils/dates';
import ExercisesTable from '@/patients/organisms/ExercisesTable';
import SurveysTable from '@/patients/organisms/SurveysTable';
import loadingIcon from '@/components/loading';
import HealthCategories from '@/healthKit/views/HealthCategories.vue';
import { getExercises, getTasks } from '@/studies/services/tasks';

const columnsExercisesList = [
  { header: 'N°' },
  { header: 'Date' },
  { header: 'Name' },
  { header: 'Download' },
];
const columnsSurveysList = [
  { header: 'N°' },
  { header: 'Date' },
  { header: 'Survey' },
  { header: 'Action' },
];

const route = useRoute();
const loading = ref(false);
const patientExercisesInformation = ref([]);
const patientSurveysInformation = ref([]);

const studyId = route.params.studyId;
const patientId = route.params.patientId;
const patientName = route.query.patientName;

const hasPatientData = computed(
  () => patientExercisesInformation.value.length || patientSurveysInformation.value.length
);

async function groupExercisesByDate(exercises) {
  const exercisesByDate = new Map();
  const allExercises = await getExercises();
  const allTasks = await getTasks();

  exercises.forEach((exercise) => {
    const date = formatDateString(exercise?.completeTimestamp);
    const exerciseDetails = mapExerciseDetails(exercise, allExercises, allTasks, date);

    addExerciseToGroup(exercisesByDate, date, exerciseDetails);
  });

  return Array.from(exercisesByDate.values());
}

function getExerciseName(exerciseId, taskReferenceId, allExercises, allTasks) {
  const exercise = allExercises.find((exercise) => exercise.id === exerciseId);
  if (exercise) return exercise.name;

  const task = allTasks.find((task) => task.id === taskReferenceId);
  return task?.taskName || 'Exercise';
}

function mapExerciseDetails(exercise, allExercises, allTasks, date) {
  const { id, exerciseVideoPath, taskReference } = exercise;
  const name = getExerciseName(id, taskReference?.id, allExercises, allTasks);

  return {
    date,
    videoPath: exerciseVideoPath,
    id,
    name,
  };
}

function addExerciseToGroup(exercisesByDate, date, exerciseDetails) {
  if (!exercisesByDate.has(date)) {
    exercisesByDate.set(date, {
      date,
      id: date,
      exercises: [],
      name: exerciseDetails.name,
    });
  }
  exercisesByDate.get(date).exercises.push(exerciseDetails);
}

async function getSurveyData(surveys) {
  const surveysWithData = surveys.filter((survey) => survey.surveyReference);
  const surveysList = await getSurveys();
  return surveysWithData.map((survey) => {
    const surveyAnswers = JSON.parse(survey.jsonResponse);
    const surveyQuestions = surveysList.find((s) => s.id === survey.surveyReference.id);
    return {
      id: survey.id,
      date: formatDateString(survey.completeTimestamp),
      surveyAnswers,
      surveyQuestions: surveyQuestions?.JsonQuestionnaire,
      name: surveyQuestions?.name,
    };
  });
}

onMounted(async () => {
  loading.value = true;
  try {
    const patientData = await getPatientData({ studyId, patientId });
    patientExercisesInformation.value = await groupExercisesByDate(patientData.exercises);
    patientSurveysInformation.value = await getSurveyData(patientData.surveys);
  } catch (error) {
    toast.error('Error loading patient data, please try again later.');
  } finally {
    loading.value = false;
  }
});
</script>

<style lang="scss" scoped>
h2 {
  margin-left: 10px;

  span {
    color: #34455A;
  }
}

.survey {
  display: flex;
  justify-content: center;
}

.btn-view {
  padding: 0.4rem 1.8em;
  background: #34455A;
  color: white;
}

.select {
  width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.loading {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
