import { ref, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import { auth, firebase } from '@/plugins/firebase/firebase';
import { toast } from 'vue3-toastify';
import { FILTER_CONSTANTS } from '@/common/static_data/constants';
import parsePhoneNumber from 'libphonenumber-js';

const ERROR_CODES = {
  MULTI_FACTOR_AUTH_REQUIRED: 'auth/multi-factor-auth-required',
  TOO_MANY_REQUESTS: 'auth/too-many-requests',
  QUOTA_EXCEEDED: 'auth/quota-exceeded',
};

export const useAuth = () => {
  const email = ref('');
  const password = ref('');
  const phoneNumber = ref('');
  const verificationCode = ref('');
  const showPhoneNumberForm = ref(false);
  const showVerificationCodeForm = ref(false);
  const verificationId = ref(null);
  const mfaResolver = ref(null);
  const recaptchaVerifier = ref(null);
  const logo = require('@/assets/logo.jpg');
  const forgotPassword = ref(false);
  const router = useRouter();
  const formattedPhoneNumber = ref('');
  const recaptchaContainer = ref(null);
  const isLoading = reactive({
    signIn: false,
    verifyCode: false,
    enrollPhoneNumber: false,
  });

  const DEFAULT_COUNTRY_CODE = 'US';
  const MIN_PHONE_LENGTH = 10;

  const stylesWithFilter = reactive({
    display: 'flex',
    justifyContent: 'center',
    fontSize: 'larger',
  });

  const toggleForgotPasswordForm = () => {
    forgotPassword.value = !forgotPassword.value;
  };

  const addPersistence = async () => {
    await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  };

  const handleSignInSuccess = async () => {
    await addPersistence();
    toast.success('Sign-in successful.');
    router.push({ name: 'Home' });
  };

  const handleEmailVerification = async (user) => {
    if (!user.emailVerified) {
      await user.sendEmailVerification();
      email.value = '';
      password.value = '';
      toast.error('Please check your email for a verification link.');
      return true;
    }
    return false;
  };

  const formatPhoneNumber = () => {
    if (!formattedPhoneNumber.value || formattedPhoneNumber.value.length < MIN_PHONE_LENGTH) return;

    try {
      const inputNumber = formattedPhoneNumber.value;
      const phoneNumberObject = inputNumber.startsWith('+')
        ? parsePhoneNumber(inputNumber)
        : parsePhoneNumber(inputNumber, DEFAULT_COUNTRY_CODE);

      if (phoneNumberObject && phoneNumberObject.isValid()) {
        formattedPhoneNumber.value = phoneNumberObject.formatNational();
        phoneNumber.value = phoneNumberObject.number;
      } else {
        const digitsOnly = inputNumber.replace(/\D/g, '');
        formattedPhoneNumber.value = digitsOnly;
        phoneNumber.value = digitsOnly;
      }
    } catch (error) {
      toast.error(`Error formatting phone number: ${error.message}`);
    }
  };

  const clearRecaptcha = () => {
    if (recaptchaVerifier.value) {
      recaptchaVerifier.value.clear();
      recaptchaVerifier.value = null;
    }
  };

  const phoneNumberIsValid = computed(() => {
    try {
      const inputNumber = formattedPhoneNumber.value;
      const phoneNumberObject = inputNumber.startsWith('+')
        ? parsePhoneNumber(inputNumber)
        : parsePhoneNumber(inputNumber, DEFAULT_COUNTRY_CODE);
      return phoneNumberObject.isValid();
    } catch (error) {
      return false;
    }
  });

  const initializeRecaptcha = () => {
    try {
      if (!recaptchaVerifier.value) {
        recaptchaVerifier.value = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
          size: 'invisible',
        });
      }
    } catch (error) {
      console.error('Error initializing reCAPTCHA:', error);
      toast.error('Error initializing reCAPTCHA. Please try again.');
    }
  };

  const handleMFASignIn = async (resolver, assertion) => {
    await resolver.resolveSignIn(assertion);
    await handleSignInSuccess();
    mfaResolver.value = null;
    clearRecaptcha();
  };

  const enrollPhoneForMFA = async (user, assertion) => {
    await user.multiFactor.enroll(assertion, `phone-${user.email}`);
    toast.success('Phone number enrolled for multi-factor authentication.');
    await handleSignInSuccess();
    clearRecaptcha();
  };

  const handleErrorDuringSignIn = (error) => {
    if (error.code === ERROR_CODES.MULTI_FACTOR_AUTH_REQUIRED) {
      return true;
    } else {
      clearRecaptcha();
      toast.error(`Sign-in error: ${error.message}`);
      return false;
    }
  };

  const handleError = (error, customMessage = '') => {
    clearRecaptcha();
    const message = customMessage || error.message || 'An unexpected error occurred.';
    toast.error(message);
  };

  const resetStateAfterError = () => {
    clearRecaptcha();
    showPhoneNumberForm.value = false;
    showVerificationCodeForm.value = false;
    phoneNumber.value = '';
    verificationCode.value = '';
    formattedPhoneNumber.value = '';
  };

  const signIn = async () => {
    initializeRecaptcha();
    isLoading.signIn = true;
    await auth.setPersistence(firebase.auth.Auth.Persistence.NONE);

    try {
      const userCredential = await auth.signInWithEmailAndPassword(email.value, password.value);
      const user = userCredential.user;

      if (await handleEmailVerification(user)) return;

      if (user.multiFactor.enrolledFactors.length === 0) {
        showPhoneNumberForm.value = true;
        return;
      }

      await handleSignInSuccess();
    } catch (error) {
      if (handleErrorDuringSignIn(error)) {
        mfaResolver.value = error.resolver;
        await sendMFAVerificationCode(mfaResolver.value);
        return;
      }
    } finally {
      isLoading.signIn = false;
    }
  };

  const sendMFAVerificationCode = async (resolver) => {
    try {
      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
      const firstMfaHint = resolver?.hints[0];
      const phoneInfoOptions = {
        multiFactorHint: firstMfaHint,
        session: resolver.session,
      };

      initializeRecaptcha();

      verificationId.value = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifier.value
      );
      showVerificationCodeForm.value = true;
      toast.info('Verification code sent to your phone.');
    } catch (error) {
      handleError(error, 'Error sending verification code');
      resetStateAfterError();
    }
  };

  const verifyCode = async () => {
    try {
      isLoading.verifyCode = true;
      const cred = firebase.auth.PhoneAuthProvider.credential(
        verificationId.value,
        verificationCode.value
      );
      const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);

      if (mfaResolver.value) {
        await handleMFASignIn(mfaResolver.value, multiFactorAssertion);
      } else {
        const user = auth.currentUser;
        await enrollPhoneForMFA(user, multiFactorAssertion);
      }

      resetStateAfterError();
      handleSignInSuccess();
    } catch (error) {
      handleError(error, 'Verification error');
    } finally {
      isLoading.verifyCode = false;
    }
  };

  const enrollPhoneNumber = async () => {
    try {
      isLoading.enrollPhoneNumber = true;
      const user = auth.currentUser;
      const multiFactorSession = await user.multiFactor.getSession();
      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();

      initializeRecaptcha();

      verificationId.value = await phoneAuthProvider.verifyPhoneNumber(
        {
          phoneNumber: phoneNumber.value,
          session: multiFactorSession,
        },
        recaptchaVerifier.value
      );

      showVerificationCodeForm.value = true;
      showPhoneNumberForm.value = false;
      toast.info('Verification code sent to your phone.');
    } catch (error) {
      handleError(error, 'Error enrolling phone number');
      resetStateAfterError();
    } finally {
      isLoading.enrollPhoneNumber = false;
    }
  };

  return {
    email,
    password,
    phoneNumber,
    verificationCode,
    showPhoneNumberForm,
    showVerificationCodeForm,
    verificationId,
    mfaResolver,
    logo,
    forgotPassword,
    stylesWithFilter,
    formattedPhoneNumber,
    isLoading,
    recaptchaContainer,
    phoneNumberIsValid,
    toggleForgotPasswordForm,
    signIn,
    verifyCode,
    enrollPhoneNumber,
    formatPhoneNumber,
  };
};
