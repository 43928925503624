import request from '@/Rest';
import { SURVEYS_IDS } from '@/common/static_data/constants';
import {
  studiesPaths,
  healthCollectionOptionsPaths,
  usersPaths,
} from '@/common/static_data/api_routes';
import {
  TranslateCollectHealthData,
  TranslateStudies,
  TranslateStudyUsers,
} from '../translate/studies.translate';

export async function getStudies() {
  try {
    const data = await request.GET(studiesPaths.list).Execute();
    const studies = data.docs?.map((element) => ({
      ...element.data(),
      id: element.id,
    }));
    if(!studies) return [];
    const studiesWithoutSurveyDocument = studies.filter(
      (study) => study.id !== SURVEYS_IDS.ON_BOARDING_SURVEY
    );
    return studiesWithoutSurveyDocument;
  } catch (error) {
    throw new Error('Error while fetching studies');
  }
}

export async function getOptionCollectionData() {
  try {
    const response = await request.GET(healthCollectionOptionsPaths.list).Execute();
    const optionsCollectionData = response.docs
      .filter((option) => option.data().isActive)
      .map((option) => TranslateCollectHealthData.DTOFromDomain(option.data()));
    return optionsCollectionData;
  } catch (error) {
    throw new Error(`Sorry, there was an error fetching the health data options ${error}`);
  }
}

export async function registerStudy(studyData, studyId) {
  try {
    const data = TranslateStudies.DomainFromDTO(studyData);
    await request.POST(`${studiesPaths.list}/${studyId}`, { data }).Execute();
  } catch (error) {
    throw new Error('Error while registering study');
  }
}

export async function updateStudy(studyData) {
  try {
    const data = TranslateStudies.DomainFromDTO(studyData);
    await request.PUT(`${studiesPaths.list}/${studyData.id}`, { data }).Execute();
  } catch {
    throw new Error('Error while updating study');
  }
}

export function getUserName(userEnrollmentSurvey) {
  if (!userEnrollmentSurvey) return '';
  const answers = JSON.parse(userEnrollmentSurvey.jsonResponse)?.item || [];
  return answers[0]?.answer[0]?.valueString || '';
}

export async function getUserTasks(userDoc) {
  try {
    const userCompletedTasksSnap = await request
      .GET(`users/${userDoc.id}/completedTasks`)
      .Execute();

    return userCompletedTasksSnap.docs.map((taskDoc) => taskDoc.data());
  } catch (error) {
    throw new Error(error.message);
  }
}

export const getStudyUsers = async (usersData, studyId) => {
  return Promise.all(
    usersData.docs
      .filter((doc) => doc.data().studies?.some((study) => study.studyReference?.id === studyId))
      .map(async (userDoc) => {
        const userData = userDoc.data();
        const userTasks = await getUserTasks(userDoc);
        const userName = getUserName(
          userTasks.find((task) => task.studyReference?.id === SURVEYS_IDS.ON_BOARDING_SURVEY)
        );
        return TranslateStudyUsers.DomainFromDTO({ id: userDoc.id, ...userData, name: userName });
      })
  );
};

export const getUsers = async (payload) => {
  try {
    const usersData = await request.GET(usersPaths.listAll).Execute();
    const studyUsers = await getStudyUsers(usersData, payload.studyId);
    return studyUsers;
  } catch (error) {
    throw new Error(error.message);
  }
};
