<template>
  <section class="wrapper-login">
    <Card v-if="forgotPassword">
      <template v-slot:card-header>
        <Logo
          :style="stylesWithFilter"
          class="text-center"
          :path="logo"
          width="120"
          title="OrthoCAP"
        />
      </template>
      <template #card-body>
        <h4 class="flex justify-center my-2 text-muted">Recover Password</h4>
        <recover-password-form
          :forgotPassword="forgotPassword"
          @toggleForgotPasswordForm="toggleForgotPasswordForm"
        ></recover-password-form>
      </template>
    </Card>

    <Card v-else-if="!showPhoneNumberForm && !showVerificationCodeForm">
      <template v-slot:card-header>
        <Logo
          :style="stylesWithFilter"
          class="text-center"
          :path="logo"
          width="120"
          title="OrthoCAP"
        />
      </template>
      <template #card-body>
        <h4 class="my-2 text-muted">Sign In</h4>
        <form class="login-form" @submit.prevent="signIn">
          <div v-if="errorMessage" class="auth-error">
            <p class="mb-1 subtitle">{{ errorMessage }}</p>
          </div>
          <div class="form-group">
            <label for="email">Email Address</label>
            <input type="email" placeholder="" id="email" v-model="email" />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input
              type="password"
              placeholder=""
              id="password"
              v-model="password"
              autocomplete="on"
            />
            <div class="flex justify-end">
              <button class="forgot-password" type="button" @click="toggleForgotPasswordForm">
                Forgot Password?
              </button>
            </div>
          </div>
          <button class="m-auto w-50 btn btn-ck" type="submit" :disabled="isLoading.signIn">
            <loading-icon :color="'white'" v-if="isLoading.signIn" size="6px" class="w-100" />
            <span v-else>Sign in</span>
          </button>
        </form>
      </template>
    </Card>

    <Card v-show="showPhoneNumberForm">
      <template v-slot:card-header>
        <Logo :style="stylesWithFilter" class="text-center" :path="logo" width="120" title="" />
      </template>
      <template #card-body>
        <h4 class="my-2 text-muted">Register Phone Number</h4>
        <form @submit.prevent="enrollPhoneNumber" class="login-form">
          <div class="form-group">
            <label for="phone">Phone Number</label>
            <input
              v-model="formattedPhoneNumber"
              type="text"
              id="phone"
              required
              @input="formatPhoneNumber"
            />
          </div>
          <div ref="recaptchaContainer" id="recaptcha-container"></div>
          <button
            class="btn btn-ck"
            type="submit"
            :disabled="isLoading.enrollPhoneNumber || !phoneNumberIsValid"
          >
            <loading-icon
              :color="'white'"
              v-if="isLoading.enrollPhoneNumber"
              size="6px"
              class="w-100"
            />
            <span v-else> Send Verification Code </span>
          </button>
        </form>
      </template>
    </Card>

    <Card v-if="showVerificationCodeForm">
      <template v-slot:card-header>
        <Logo
          :style="stylesWithFilter"
          class="text-center"
          :path="logo"
          width="120"
          title="OrthoCAP"
        />
      </template>
      <template #card-body>
        <h4 class="my-2 text-muted">Enter Verification Code</h4>
        <form @submit.prevent="verifyCode" class="login-form">
          <div class="form-group">
            <OTPInput @update-value="(otp) => (verificationCode = otp)" />
          </div>
          <button class="btn btn-ck" type="submit" :disabled="isLoading.verifyCode">
            <loading-icon :color="'white'" v-if="isLoading.verifyCode" size="6px" class="w-100" />
            <span v-else>Verify Code</span>
          </button>
        </form>
      </template>
    </Card>
  </section>
</template>

<script setup>
import Logo from '../atoms/Logo';
import Card from '../atoms/Card';
import RecoverPasswordForm from '../organisms/RecoverPasswordForm.vue';
import loadingIcon from '@/components/loading';
import { useAuth } from '../composables/useAuth';
import OTPInput from '@/components/OTPInput.vue';

const {
  email,
  enrollPhoneNumber,
  forgotPassword,
  formatPhoneNumber,
  formattedPhoneNumber,
  isLoading,
  logo,
  password,
  phoneNumberIsValid,
  recaptchaContainer,
  showPhoneNumberForm,
  showVerificationCodeForm,
  signIn,
  stylesWithFilter,
  toggleForgotPasswordForm,
  verificationCode,
  verifyCode,
} = useAuth();
</script>

<style lang="scss" scoped>
.forgot-password {
  display: flex;
  justify-content: flex-end;
  border: none;
  background-color: transparent;

  &:hover {
    color: blue;
    cursor: pointer;
  }
}

.btn-ck:disabled {
  cursor: not-allowed;
}

.wrapper-login {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  height: 100vh;
  background: $background-login;

  .login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2rem;

    .form-group {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;

      label {
        text-transform: uppercase;
        text-align: start;
        margin-bottom: 3px;
        font-size: 10pt;
        color: #6c757d;
      }

      input {
        margin-bottom: 0.5rem;
        font-size: 1em;
        border: 0;
        border-bottom: 1px solid #6c757d;
        -webkit-appearance: none;
        border-radius: 0;
        padding: 0;
        cursor: text;
      }

      :focus {
        outline: 0;
        border-bottom: 1px solid #b61440;
      }

      a {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        &.forgot {
          font-size: 10pt;
          color: #6c757d;
        }
      }
    }
  }

  .auth__social-networks {
    margin-bottom: 0.5rem;
    display: grid;
    gap: 10px;

    .subtitle {
      margin-top: 0;
      text-align: center;
    }
  }

  .auth__error {
    margin-bottom: 0.5rem;
    display: grid;
    gap: 10px;
    margin-top: 0;
    text-align: center;
    color: red;
  }
}
</style>
