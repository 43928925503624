<template>
  <div>
    <router-view/>
  </div>
</template>

<style lang="scss">
@import "@/assets/styles/style.scss";

body {
  min-height: 100vh;
  margin: 0;
  font-family: 'Lato Regular', Helvetica, sans-serif;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>