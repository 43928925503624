<template>
  <section>
    <button @click="toggleModal" class="surveysModal-toggle-button" :class="{ 'open': isTogglingModal }">
      <span v-if="isTogglingModal"><img src="@/assets/icons/xmark-icon.svg" alt="X Mark Icon" /></span>
      <span v-else><b>View Existing Surveys</b> <img src="@/assets/icons/eye-icon.svg" alt="View Icon" /></span>
    </button>

    <Transition name="slide">
      <section v-if="isTogglingModal" class="surveysModal">
        <div class="surveysModal-content">
          <h2 class="flex justify-center mb-5">Existing Surveys</h2>

          <div>
            <label for="filterStudy">Filter survey</label>
            <input v-model="searchExistingSurvey" id="filterStudy" type="text" placeholder="Search by Study Name"
              class="search-input">
          </div>

          <loading-icon class="flex justify-center" v-if="isLoading" size="8px" />
          <ul v-else-if="filteredSurveys.length">
            <li v-for="survey in filteredSurveys" :key="survey.id" class="mb-5">
              <p><span>Survey Id:</span> {{ survey.id }} <br> <span>Survey Name:</span> {{ survey.name }}</p>
              <div class="flex justify-center">
                <button type="button" class="mt-5 mr-2 w-60 list-button btn btn-ck fill-danger"
                  @click="toggleConfirmDeleteModal(survey.id)">
                  <loading-icon class="flex justify-center" :color="'white'" v-if="surveyDeleteId === survey.id"
                    size="6px" />
                  <strong v-else>Delete Survey</strong>
                </button>
              </div>
            </li>
          </ul>
          <p class="flex justify-center" v-else-if="!existingSurveys.length">There are no existing surveys...</p>
          <p class="flex justify-center" v-else>Survey name not found...</p>
        </div>
      </section>
    </Transition>

    <modal title="Delete Survey" :maxWidth="'450px'" :withoutCloseOption="true" :showModal="isTogglingConfirmDeleteModal"
      @closeModal="toggleConfirmDeleteModal">
      <template v-slot:content>
        <form class="form" @submit.stop.prevent="handleDeleteSurvey">
          <div class="m-2 form-group">
            Are you sure you want to delete the selected survey?
          </div>

          <div class="flex gap-3 mt-5 w-100">
            <button class="m-auto btn btn-ck" type="button" @click="toggleConfirmDeleteModal">
              <strong>Cancel</strong>
            </button>
            <button class="m-auto btn btn-ck fill-danger" type="submit">
              <loading-icon class="flex justify-center w-100" :color="'white'" v-if="isLoadingDelete" size="6px" />
              <strong v-else>
                Delete
              </strong>
            </button>
          </div>
        </form>
      </template>
    </modal>
  </section>
</template>

<script setup>
import { ref, computed } from 'vue';
import { toast } from 'vue3-toastify';
import { deleteSurvey } from '@/surveys/services/surveys';

import Modal from "@/components/Modal";
import loadingIcon from "@/components/loading";

const props = defineProps({
  existingSurveys: {
    type: Array,
    required: true
  },
  isLoading: {
    type: Boolean,
    required: true
  },
})

const emit = defineEmits(['fetchSurveys'])

const isLoadingDelete = ref(false)

const isTogglingModal = ref(false);
const isTogglingConfirmDeleteModal = ref(false);

const surveyDeleteId = ref('')
const searchExistingSurvey = ref('');


const toggleModal = () => isTogglingModal.value = !isTogglingModal.value;
function toggleConfirmDeleteModal(surveyId) {
  isTogglingConfirmDeleteModal.value = !isTogglingConfirmDeleteModal.value;
  surveyDeleteId.value = surveyId
}

const filteredSurveys = computed(() => {
  if (!searchExistingSurvey.value.trim()) {
    return props.existingSurveys;
  }

  return props.existingSurveys.filter(survey =>
    survey.name.includes(searchExistingSurvey.value.trim())
  );
});

const handleDeleteSurvey = async () => {
  try {
    isLoadingDelete.value = true;
    await deleteSurvey(surveyDeleteId.value)
    surveyDeleteId.value = '';
    toast.success("The survey has been deleted successfully");
    emit('fetchSurveys');
  } catch (error) {
    toast.error(error.message)
  } finally {
    isLoadingDelete.value = false;
    toggleConfirmDeleteModal('');
  }
}
</script>

<style lang="scss" scoped>
.list-button {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #fff;
  border: none;
  margin-right: 5px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

.surveysModal {
  z-index: 1;
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100vh;
  background-color: #fff;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.surveysModal-content {
  padding: 10px 15px;

  & h2,
  span,
  label {
    color: #34455A;
  }

  & p {
    color: black;
  }

  & ul {
    padding: 0;
  }

  & li {
    position: relative;
    padding-left: 1.5em;
    list-style: none;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;

    &:before {
      content: '\25C6';
      position: absolute;
      left: 0;
      color: #34455A;
    }
  }

  & .search-input {
    width: 100%;
    padding: 10px;
    margin-top: 8px;
    margin-bottom: 40px;
    border-radius: 5px;
    border: 1px solid #ccc;
    text-align: center;
    cursor: pointer;
  }
}

.surveysModal-toggle-button {
  top: 30%;
  position: fixed;
  right: 0;
  background-color: #fff;
  border: none;
  color: #34455A;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
  box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
  transition: top 0.4s ease-in-out;

  & span {
    display: flex;
    gap: 1rem;
  }
}

.surveysModal-toggle-button.open {
  top: 3px;
  right: 0;
  z-index: 2;
  box-shadow: none;
}

.slide-enter-active,
.slide-leave-active {
  transition: right 0.5s ease-in-out;
}

.slide-enter-from {
  right: -300px;
}

.slide-leave-to {
  right: -360px;
}
</style>