export const GET=async (path,payload)=>{

}

export const  POST= async (path,payload)=>{
    
}

export const PATH= async (path,payload)=>{

}

export const  PUT= async (path,payload)=>{

}

export const DELETE= async (path,payload)=>{

}