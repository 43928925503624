<template>
  <existing-surveys-modal
    :existingSurveys="existingSurveys"
    :isLoading="isLoading"
    @fetch-surveys="fetchSurveys"
  ></existing-surveys-modal>
  <section class="wrapper-form">
    <Card>
      <template v-slot:card-header>
        <div class="card-header">
          <tooltip>
            <template v-slot:tooltip-trigger>
              <h1 id="title" class="mb-1 text-center text-muted">Register Survey</h1>
            </template>
            <template v-slot:tooltip-content>
              <a href="https://www.hl7.org/fhir/questionnaire.html" target="_blank">
                Built using the FHIR framework for questionnaire resources
              </a>
            </template>
          </tooltip>
        </div>
      </template>
      <template v-slot:card-body>
        <form class="form" @submit.prevent="registerFHIRQuestionnaireStructure">
          <div class="w-100 form-group">
            <label for="surveyName" class="text-muted">Survey Name</label>
            <input
              autocomplete="off"
              class="form-input w-100"
              type="text"
              id="surveyName"
              placeholder="Onboarding Survey"
              v-model="survey.name"
              required
            />
          </div>

          <div class="w-100 form-group">
            <label for="survey.valueQuestions" class="text-muted">Survey Items</label>
            <register-items-form @add-survey-item="handleAddSurveyItem" />
            <ul v-if="survey.items?.length" class="h-300 options-list">
              <li v-for="(item, index) in survey.items" :key="`item-${index}-${item}`">
                <section class="ml-2">
                  <span class="flex justify-center mb-2">
                    Item {{ index + 1 }} - Type {{ item.type }}
                  </span>
                  <p class="flex justify-center break-words-smaller">{{ item.text }}</p>
                </section>
                <section>
                  <div>
                    <button
                      type="button"
                      class="bg-blue mr-2 btn btn-ck delete-button"
                      @click="openEditModal(item, index)"
                    >
                      Edit
                    </button>
                  </div>
                  <div class="mt-3 mb-2">
                    <button
                      type="button"
                      class="bg-red mr-2 btn btn-ck delete-button fill-danger"
                      @click="handleRemoveSurveyItem(index)"
                    >
                      Delete
                    </button>
                  </div>
                </section>
              </li>
            </ul>
          </div>

          <button id="b-form" class="m-auto btn btn-ck" type="submit">
            <loading-icon
              class="flex justify-center w-100"
              :color="'white'"
              v-if="isLoadingSubmit"
              size="5px"
            />
            <span v-else>Register Survey</span>
          </button>
        </form>
      </template>
    </Card>
  </section>

  <edit-items-form
    :isTogglingEditModal="isTogglingEditModal"
    :survey="survey"
    :editingItem="editingItem"
    :editingIndex="editingIndex"
    @toggle-edit-modal="toggleEditModal"
    @set-new-item-type="handleSetNewItemType"
    @submit-edit-survey-item="handleSubmitEditSurveyItem"
  />
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { toast } from 'vue3-toastify';
import { v4 as uuidv4 } from 'uuid';

import {
  getSurveys,
  registerSurvey,
  generateFHIRQuestionnaireStructure,
} from '@/surveys/services/surveys';

import Card from '@/auth/atoms/Card';
import Tooltip from '@/components/Tooltip';
import loadingIcon from '@/components/loading';
import RegisterItemsForm from '@/surveys/molecules/RegisterItemsForm';
import EditItemsForm from '@/surveys/molecules/EditItemsForm';
import ExistingSurveysModal from '@/surveys/molecules/ExistingSurveysModal';

const isLoading = ref(false);
const isLoadingSubmit = ref(false);

const existingSurveys = ref([]);

let survey = ref({
  name: '',
  items: [],
});

const isTogglingEditModal = ref(false);
const selectedItemType = ref('');
const editingItem = ref({});
const editingIndex = ref(-1);

const handleRemoveSurveyItem = (index) => survey.value.items.splice(index, 1);

const handleAddSurveyItem = (newItem) => {
  let items = [...survey.value.items, { ...newItem }];
  survey.value = { ...survey.value, items };
  toast.success('The item has been added successfully');
};

function toggleEditModal() {
  isTogglingEditModal.value = !isTogglingEditModal.value;
}

function openEditModal(item, index) {
  selectedItemType.value = item.type;
  editingItem.value = { ...item };
  editingIndex.value = index;
  toast.success('The item is ready to be edited');
  toggleEditModal();
}

const handleSubmitEditSurveyItem = (editedItem) =>
  (survey.value.items[editingIndex.value] = { ...editedItem });

const handleSetNewItemType = (newType) => (editingItem.value.type = newType);

async function registerFHIRQuestionnaireStructure() {
  try {
    isLoadingSubmit.value = true;

    const validateIfTheStudyAlreadyExists = existingSurveys.value.some(
      (existingSurvey) => existingSurvey.name === survey.value.name
    );

    if (validateIfTheStudyAlreadyExists) {
      toast.error('Survey name already exists');
    } else if (survey.value.items.length) {
      const surveyId = uuidv4();
      const questionnaire = generateFHIRQuestionnaireStructure(survey.value, surveyId);
      await registerSurvey(questionnaire, surveyId);
      fetchSurveys();

      survey.value = {
        name: '',
        items: [],
      };

      toast.success('The survey was successfully registered');
    } else {
      toast.warning('You must add items to continue');
    }
  } catch (error) {
    toast.error(error.message);
  } finally {
    isLoadingSubmit.value = false;
  }
}

async function fetchSurveys() {
  isLoading.value = true;
  try {
    const survey = await getSurveys();
    existingSurveys.value = survey;
    isLoading.value = false;
  } catch (error) {
    toast.error(error.message);
  } finally {
    isLoading.value = false;
  }
}

onMounted(() => {
  fetchSurveys();
});
</script>

<style lang="scss" scoped>
.wrapper-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  height: 100vh;

  .card-header {
    margin-bottom: 2rem;

    h1#title {
      font-size: 2rem;
    }

    span {
      font-size: 10pt;
    }

    @media screen and (max-width: 510px) {
      margin-bottom: 1rem;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;

    .form-group {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;

      label {
        width: fit-content;
        text-align: start;
        margin-bottom: 3px;
        font-size: 10pt;
      }

      a {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }

    button#b-form {
      margin-top: 1rem;
    }
  }
}

.delete-button {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #fff;
  border: none;
  margin-right: 5px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

.bg-blue {
  background-color: #34455A;
}

a {
  color: white;
  text-decoration: none;
  border-bottom: 1px solid red;

  &:hover {
    color: lightgrey;
  }
}
</style>
