import { useRoute, useRouter } from 'vue-router';
import { ref, computed } from "vue";

export function useOptions(array) {
    const limit = ref(10);
    const currentPage = ref(1);
    const route = useRoute()
    const router = useRouter();

    const paginationOptions = computed(() => {
        return {
            limit: [10, 20],
            total: array?.length,
            currentPage: currentPage.value,
        };
    });

    const getPageItems = computed(() => {
        const items = array;
        const lowerLimit = (currentPage.value - 1) * limit.value;
        const upperLimit = currentPage.value * limit.value;
        return items?.slice(lowerLimit, upperLimit);
    });

    function handlePagination(pagination) {
        currentPage.value = pagination.currentPage;
        router.push({
            path: route.path, query: {
                currentPage: currentPage.value
            }
        })
        limit.value = pagination.limit;
        const total = array?.length;
        const currentPageIsGreater = currentPage.value > Math.ceil(total / limit.value);
        if (currentPageIsGreater) {
            currentPage.value = Math.ceil(total / limit.value);
        }
    }

    return { paginationOptions, getPageItems, handlePagination, limit, currentPage  }
}