import {
    auth,
    secondaryProvider
} from "@/plugins/firebase/firebase";
import request from "@/Rest";

async function SignIn({ email, password }) {
    try {
        if(!email.length || !password.length) {
            const errorMessage = 'You must enter a correct email an password';
            throw new Error(errorMessage)
        } else {
            await auth.signInWithEmailAndPassword(email, password);
        }
    } catch (error) {
        if (error.code === "auth/network-request-failed") {
            throw new Error("No internet connection");
        }
        else {
            throw new Error("The username or password is incorrect");
        }
    }
}

async function SignUpNoPassword({ email, studies }) {
    const { v4: uuidv4 } = require("uuid");
    try {
        const { user } = await secondaryProvider.createUserWithEmailAndPassword(email, uuidv4());
        await secondaryProvider.sendPasswordResetEmail(email);
        request.POST(`users_roles/${user.uid}`, { data: { rol: "doctor", studies: studies } })
    } catch (error) {
        throw new Error(error);
    }
}

async function ForgotPassword(email) {
    try {
        if(!email.length) {
            const errorMessage = 'You must enter an email';
            throw new Error(errorMessage)
        } else {
            await auth.sendPasswordResetEmail(email)
        }
    } catch (error) {
        if (error.code === 'auth/network-request-failed') {
            const errorMessage = 'No internet connection';
            throw new Error(errorMessage)
        }
        else {
            const errorMessage = 'The email is incorrect';
            throw new Error(errorMessage)
        }
    }
}

async function Logout() {
    try {
        await auth.signOut();
    } catch (error) {
        throw new Error(error)
    }
}

export {
    SignIn,
    SignUpNoPassword,
    ForgotPassword,
    Logout,
}