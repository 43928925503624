import { GRAPH_TYPES } from '@/healthKit/utils/HealthKit';
import { formatDate } from '@/common/utils/formDate';

export const formatToValueToFixed = (value = 0) => value.toFixed(2);

const formatScatterData = (hkCode, sortedData) => [
  {
    name: hkCode,
    data: sortedData.map((entry) => [
      new Date(entry.startDate).getTime(),
      formatToValueToFixed(entry.value),
    ]),
  },
];

const formatLineData = (hkCode, sortedData) => [
  {
    name: hkCode,
    data: sortedData.map((entry) => ({
      x: new Date(entry.startDate).getTime(),
      y: formatToValueToFixed(entry.value),
    })),
  },
];

const formatRangeData = (hkCode, sortedData) => [
  {
    name: hkCode,
    data: sortedData.map((entry) => ({
      x: entry.code.displayName,
      y: [new Date(entry.startDate).getTime(), new Date(entry.endDate).getTime()],
    })),
  },
];

const formatBarData = (hkCode, sortedData) => [
  {
    name: hkCode,
    data: sortedData.map((entry) => ({
      x: formatDate(entry.startDate),
      y: formatToValueToFixed(entry.value),
    })),
  },
];

const formatSleepAnalysis = (entry) => {
  const startDate = formatDate(entry.startDate);
  const endDate = formatDate(entry.endDate);

  return {
    x: startDate,
    y: formatToValueToFixed(entry.value),
    startDate,
    endDate,
  };
};

export const formatLineDataSleepAnalysis = (hkCode, sortedData) => {
  return [
    {
      name: hkCode,
      data: sortedData.map(formatSleepAnalysis),
    },
  ];
};

export const formatChartData = (graphType, hkCode, sortedData) => {
  switch (graphType) {
    case GRAPH_TYPES.SCATTER:
      return formatScatterData(hkCode, sortedData);
    case GRAPH_TYPES.LINE:
      return formatLineData(hkCode, sortedData);
    case GRAPH_TYPES.RANGE:
      return formatRangeData(hkCode, sortedData);
    case GRAPH_TYPES.BAR:
      return formatBarData(hkCode, sortedData);
    default:
      return [];
  }
};
