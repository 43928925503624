<template>
  <section class="wrapper-categories">
    <bread-crumb
      :breadcrumbs="currentBreadcrumbs"
      :userId="userId"
      :studyId="studyId"
      @navigate="navigateToComponent"
    />
    <categories-list
      v-if="currentComponent === COMPONENTS.CATEGORIES"
      @onSelectCategory="selectCategory"
    />
    <category-detail
      v-if="currentComponent === COMPONENTS.CATEGORY_DETAIL"
      :userId="userId"
      :studyId="studyId"
      :category="selectedCategory"
      @onSelectCategoryDetail="selectCategoryDetail"
    />
    <statistic-section
      v-if="currentComponent === COMPONENTS.STATISTICS"
      :data-type="selectedDataType"
    />
  </section>
</template>

<script lang="js" setup>
import { ref, computed, provide } from 'vue';
import CategoriesList from '../organisms/CategoriesList.vue';
import BreadCrumb from '@/components/BreadCrumb.vue';
import CategoryDetail from '../organisms/CategoryDetail.vue';
import StatisticSection from '../organisms/StatisticSection.vue';
import { useRoute } from 'vue-router';
import { HK_CATEGORIES } from '../utils/HealthKit';

const route = useRoute();

const COMPONENTS = {
  CATEGORIES: 'categories',
  CATEGORY_DETAIL: 'categoryDetail',
  STATISTICS: 'statistics',
};

const STORAGE_KEYS = {
  BREADCRUMB_PATH: 'breadcrumbPath',
};

const currentComponent = ref(COMPONENTS.CATEGORIES);
const selectedCategory = ref(null);
const selectedDataType = ref(null);
const userId = ref(route.params.patientId);
const studyId = ref(route.params.studyId);

const breadcrumbPath = ref([
  {
    label: 'Health Categories',
    name: COMPONENTS.CATEGORIES,
    component: CategoriesList,
    active: true,
  },
  {
    label: 'Activity',
    name: COMPONENTS.CATEGORY_DETAIL,
    param: 'categoryName',
    component: CategoryDetail,
    active: false,
  },
  {
    label: 'Statistics',
    name: COMPONENTS.STATISTICS,
    component: StatisticSection,
    active: false,
  },
]);

const currentBreadcrumbs = computed(() =>
  breadcrumbPath.value.slice(0, breadcrumbPath.value.findIndex((item) => item.active) + 1)
);

function navigateToComponent(name, params = {}) {
  breadcrumbPath.value = breadcrumbPath.value.map((item) => {
    const isActive = item.name === name;
    const updatedItem = { ...item, active: isActive };

    if (isActive) {
      currentComponent.value = name;
      if (item.param && params[item.param]) {
        updatedItem.label = params[item.param];
      }
    }

    return updatedItem;
  });

  localStorage.setItem(STORAGE_KEYS.BREADCRUMB_PATH, JSON.stringify(breadcrumbPath.value));
}

function selectCategory(category) {
  selectedCategory.value = category;
  navigateToComponent(COMPONENTS.CATEGORY_DETAIL, { categoryName: category.name });
}

function selectCategoryDetail(dataTypeSelected) {
  const category = HK_CATEGORIES[selectedCategory.value.name];
  const dataType = category.dataType.find((dt) => dt.key === dataTypeSelected.codeValue);
  selectedDataType.value = dataType;
  navigateToComponent(COMPONENTS.STATISTICS);
}

provide('collectedFormData', {
  studyId: studyId.value,
  userId: userId.value,
});
</script>

<style lang="scss" scoped>
.wrapper-categories {
  padding: 1rem 2rem;
}
</style>
