<template>
  <modal
    title="Register Group Option"
    :maxWidth="'450px'"
    :showModal="isTogglingGroupOptionsModal"
    @closeModal="$emit('toggleAddGroupOptionsModal')"
  >
    <template v-slot:content>
      <form class="form" @submit.stop.prevent="handleSubmitAddOptionToGroup">
        <section class="m-2">
          <SwitchInput
            v-model="usesPredefinedOptions"
            label="Use Predefined Options:"
            class="mb-5"
          />
          <div v-if="usesPredefinedOptions">
            <label for="option" class="flex text-muted mb-2">Option:</label>
            <multiselect
              placeholder="Type to search an option..."
              class="dropdown"
              v-model="groupOption"
              :options="handleQueryOptions"
              :canDeselect="false"
              :canClear="false"
              :searchable="true"
              :min-chars="1"
              :delay="1000"
              @select="handleSelectOption"
            />
          </div>
          <div v-else>
            <label for="optionTitle" class="flex text-muted">Option Title:</label>
            <input
              autocomplete="off"
              class="w-100 form-input mt-1"
              type="text"
              id="optionTitle"
              placeholder="Enter a Title"
              v-model="groupOptionObject.display"
              required
              @blur="generateOptionValue"
            />
            <label for="optionCode" class="flex text-muted mt-3">Option Value:</label>
            <input
              autocomplete="off"
              class="w-100 form-input mt-1"
              type="text"
              id="optionCode"
              placeholder="Enter a Value"
              v-model="groupOptionObject.code"
              required
            />
          </div>
        </section>

        <section class="flex gap-3 mt-5 w-100">
          <button class="btn btn-ck m-auto" type="submit">Add Option</button>
        </section>
      </form>
    </template>
  </modal>
</template>

<script setup>
import { ref } from 'vue';
import { toast } from 'vue3-toastify';

import Modal from '@/components/Modal';
import Multiselect from '@vueform/multiselect';
import { getSurveyAnswerOptions } from '@/surveys/services/surveys';
import SwitchInput from '@/components/SwitchInput.vue';
import { v4 as uuidv4 } from 'uuid';

const props = defineProps({
  isTogglingGroupOptionsModal: Boolean,
  item: Object,
});

const emit = defineEmits(['toggleAddGroupOptionsModal', 'addGroupItemOption']);

const DEFAULT_OPTION = { code: '', display: '', system: generateSystemValue() };

const groupOption = ref('');
const groupOptionObject = ref({ ...DEFAULT_OPTION });
const usesPredefinedOptions = ref(false);

async function handleQueryOptions(query) {
  if (!query) return;
  return await getSurveyAnswerOptions(query.toLowerCase());
}

function handleSelectOption(_, selectedObject) {
  const { code, system, display } = selectedObject;
  groupOptionObject.value = { code, system, display };
}

function handleSubmitAddOptionToGroup() {
  emit('addGroupItemOption', groupOptionObject.value);
  groupOption.value = '';
  groupOptionObject.value = { ...DEFAULT_OPTION };
  toast.success('The option has been added successfully');
  emit('toggleAddGroupOptionsModal');
}

function generateSystemValue() {
  return `urn:uuid:${uuidv4()}`;
}

function generateOptionValue() {
  groupOptionObject.value = {
    ...groupOptionObject.value,
    code: groupOptionObject.value.display.toLowerCase().replace(/ /g, '-'),
  };
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.dropdown {
  margin-top: 2px;
  --ms-border-width: 1px;
  --ms-dropdown-border-width: 1px;
  --ms-border-width-active: 1px;

  --ms-option-bg-pointed: #f3f8fb;
  --ms-option-bg-selected: #e5f1ff;
  --ms-option-color-selected: #052049;
  --ms-option-bg-selected-pointed: #e5f1ff;
  --ms-option-color-selected-pointed: #052049;
  --ms-ring-color: transparent;
}
</style>
