export const TIME_CONSTANTS = {
  MILLISECONDS: 1000,
};

export const FILTER_CONSTANTS = {
  RED_TO_BLUE_FILTER:
    'brightness(0) saturate(100%) invert(12%) sepia(66%) saturate(5799%) hue-rotate(216deg) brightness(90%) contrast(101%)',
};

export const SURVEYS_IDS = {
  ON_BOARDING_SURVEY: 'OnBoardingSurvey',
};

export const ROLES = {
  SUPER_ADMIN: 'superAdmin',
};

export const HEALTHKIT_IMAGES = {
  HEART_WITH_HAND:
    'https://user-images.githubusercontent.com/82823662/120020717-b6ae7280-bfaf-11eb-8fdc-cd8b4ce10dde.png',
};
