<template>
  <div>
    <apex-chart ref="chart" type="scatter" height="250" :options="chartOptions" :series="series" />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import ApexChart from 'vue3-apexcharts';

const chart = ref(null);

const props = defineProps({
  series: {
    type: Array,
    required: true,
  },
});

const chartOptions = computed(() => ({
  series: props.series,
  chart: {
    height: 350,
    type: 'scatter',
    zoom: {
      enabled: true,
      type: 'xy',
    },
  },
  xaxis: {
    type: 'datetime',
  },
  yaxis: {
    tickAmount: 7,
  },
}));
</script>
