<template>
  <div class="alt-date">
    <date-selector
      :calendar="withCalendar"
      ref="startElement"
      :showPopup="startDateTooltip"
      @changeDate="handleChangeDates($event, 'startDate')"
      :defaultDate="startDate"
      label="Start Date"
    />
    <date-selector
      v-if="range"
      :calendar="withCalendar"
      ref="endElement"
      :showPopup="endDateTooltip"
      @changeDate="handleChangeDates($event, 'endDate')"
      :defaultDate="endDate"
      label="End Date"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import DateSelector from './DateSelector.vue';

const props = defineProps({
  modelValue: [Date, Object],
  range: {
    type: Boolean,
    default: false,
  },
  withCalendar: {
    type: Boolean,
    default: false,
  },
  defaultDate: [Date, Object],
});

const emit = defineEmits(['update:modelValue']);

const startElement = ref(null);
const endElement = ref(null);
const startDateTooltip = ref(false);
const endDateTooltip = ref(false);
const startDate = ref(props.defaultDate?.startDate || new Date());
const endDate = ref(props.defaultDate?.endDate || new Date());
const date = ref(
  props.range ? { startDate: startDate.value, endDate: endDate.value } : startDate.value
);

onMounted(() => {
  emit('update:modelValue', date.value);
});

const handleChangeDates = (value, key) => {
  if (value) {
    date.value = !props.range ? value : updateRangeDates(value, key);
    emit('update:modelValue', date.value);
  }
};

const updateRangeDates = (value, key) => {
  if (key === 'startDate' && date.value?.endDate < value) {
    endDate.value = value;
    return { startDate: value, endDate: value };
  }
  if (key === 'endDate' && date.value?.startDate > value) {
    startDate.value = value;
    return { startDate: value, endDate: value };
  }
  return { ...date.value, [key]: value };
};

const handleElementClick = (element) => {
  startDateTooltip.value = startElement.value?.$el.contains(element.target);
  if (props.range) {
    endDateTooltip.value = endElement.value?.$el.contains(element.target);
  }
};

window.addEventListener('click', handleElementClick);

onUnmounted(() => {
  window.removeEventListener('click', handleElementClick);
});
</script>

<style lang="scss" scoped>
.alt-date {
  position: relative;
  display: inline-flex;
  gap: 10px;
}
</style>
