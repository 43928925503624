<template>
  <section>
    <button
      @click="toggleModal"
      class="studiesModal-toggle-button"
      :class="{ open: isTogglingModal }"
    >
      <span v-if="isTogglingModal">
        <img src="@/assets/icons/xmark-icon.svg" alt="X Mark Icon" />
      </span>
      <span v-else>
        <b>View Existing Studies</b>
        <img src="@/assets/icons/eye-icon.svg" alt="View Icon" />
      </span>
    </button>

    <Transition name="slide">
      <section v-if="isTogglingModal" class="studiesModal">
        <div class="studiesModal-content">
          <h2 class="flex justify-center mb-5">Existing Studies</h2>

          <div>
            <label for="filterStudy">Filter study</label>
            <input
              v-model="searchExistingStudy"
              id="filterStudy"
              type="text"
              placeholder="Search by Study Identifier"
              class="search-input"
            />
          </div>

          <loading-icon class="flex justify-center" v-if="isLoading" size="8px" />
          <ul v-else-if="filteredStudies.length">
            <li v-for="study in filteredStudies" :key="study.id" class="mb-5">
              <p>
                <span>Study Id:</span> {{ study.idNumber }} <br />
                <span>Study Name:</span> {{ study.name }}
              </p>
              <button @click="handleEditStudy(study)" class="mt-2 list-button btn btn-ck">
                Edit Tasks
              </button>
            </li>
          </ul>
          <p class="flex justify-center" v-else>Study Id not found...</p>
        </div>
      </section>
    </Transition>
  </section>
  <section class="wrapper-form">
    <div class="card-header">
      <h1 id="title" class="mb-1 text-center text-muted">
        {{ `${isEdition ? 'Edit' : 'Register'} Study` }}
      </h1>
    </div>
    <form class="form" @submit.prevent="handleSubmitRegister">
      <div class="w-100 form-group">
        <label for="studyId" class="text-muted">Study Identifier</label>
        <input
          :disabled="isEdition"
          class="form-input"
          type="text"
          placeholder="12345"
          id="studyId"
          v-model="studyData.studyId"
          required
        />
      </div>
      <div class="w-100 form-group">
        <label for="studyName" class="text-muted">Study Name</label>
        <input
          :disabled="isEdition"
          class="form-input"
          type="text"
          placeholder="Knee Study"
          id="studyName"
          v-model="studyData.studyName"
          required
        />
      </div>
      <div class="w-100 form-group">
        <label for="studyDuration" class="text-muted">Study Duration (in Days)</label>
        <input
          :disabled="isEdition"
          class="form-input"
          type="number"
          placeholder="0"
          min="1"
          id="studyDuration"
          v-model="studyData.studyDuration"
          required
        />
      </div>
      <div class="w-100 form-group">
        <label for="studyDuration" class="text-muted">Study Tasks</label>
        <tasks-form
          :selectedTask="selectedTask"
          :isEdition="isEdition"
          :isTaskEdition="isTaskEdition"
          @edit-task="handleUpdateTask"
          @add-task="handleAddTask"
          @exit-task-edition="isTaskEdition = false"
        />
        <ul v-if="tasks.length" class="task-list">
          <li v-for="(task, index) in activeTasks" :key="task">
            <div class="task">
              <div>
                <p class="ml-2"><span>Task:</span> {{ task.taskName ?? task.name }}</p>
              </div>
              <div>
                <button
                  type="button"
                  class="btn btn-ck delete-button fill-danger"
                  @click="handleDeleteTask(index, task)"
                >
                  Delete
                </button>
                <button
                  class="mt-3 w-full btn btn-ck delete-button"
                  type="button"
                  @click="handleEditTask(task)"
                >
                  Edit
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="w-100 form-group">
        <label for="studyConsent" class="text-muted">Study Consent</label>
        <register-study-consent
          :disabled="isEdition"
          :consent="studyData.studyConsent"
          @add-consent="handleAddConsent"
        />
      </div>
      <CollectDataStudy v-model:study-data="studyData" />
      <div class="flex">
        <button
          :disabled="isLoadingSubmit"
          v-if="isEdition"
          class="m-auto mt-3 btn btn-ck fill-danger"
          type="reset"
          @click="handleExitEdition"
        >
          Exit Edition
        </button>
        <button id="b-form" class="m-auto btn btn-ck" type="submit" :disabled="isLoadingSubmit">
          <loading-icon
            class="flex justify-center w-100"
            :color="'white'"
            v-if="isLoadingSubmit"
            size="5px"
          />
          <span v-else>{{ isEdition ? 'Save Changes' : 'Register Study' }}</span>
        </button>
      </div>
    </form>
  </section>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { toast } from 'vue3-toastify';
import { v4 as uuidv4 } from 'uuid';
import { registerStudy, getStudies, updateStudy } from '@/studies/services/studies';
import { registerTask, getTasks, updateTask, deleteTask } from '@/studies/services/tasks';
import loadingIcon from '@/components/loading';
import TasksForm from '@/studies/atoms/TasksForm';
import RegisterStudyConsent from '@/studies/atoms/RegisterStudyConsent';
import CollectDataStudy from '../atoms/CollectDataStudy.vue';
import { HK_SLEEP_ANALYSIS } from '../utils';

const defaultStudyInformation = Object.freeze({
  studyId: '',
  studyName: '',
  studyDuration: 1,
  studyConsent: '',
  participantHealthData: {
    shouldCollect: false,
    dataTypes: [],
    dataFromDaysAgo: 0,
    frequencyInHours: 0,
    executeSleepAnalysis: false,
  },
});

const isLoading = ref(false);
const isLoadingSubmit = ref(false);
const isTogglingModal = ref(false);
const searchExistingStudy = ref('');
const existingStudies = ref([]);
const studyData = ref(structuredClone(defaultStudyInformation));
const tasks = ref([]);

const isEdition = ref(false);
const isTaskEdition = ref(false);
const tasksByStudy = ref([]);
const selectedTask = ref({});

const activeTasks = computed(() => tasks.value.filter((task) => !task.deleted));

const toggleModal = () => (isTogglingModal.value = !isTogglingModal.value);

const handleAddTask = (newTaskData) => tasks.value.push(newTaskData);
const handleRemoveTask = (index) => tasks.value.splice(index, 1);

const handleAddConsent = (consent) => (studyData.value.studyConsent = consent);

function prepareParticipantHealthData(participantHealthData, sleepAnalysisType) {
  const isSleepAnalysisIncluded = participantHealthData?.dataTypes?.includes(sleepAnalysisType);
  const filteredHealthDataTypes = isSleepAnalysisIncluded
    ? participantHealthData?.dataTypes?.filter((dataType) => dataType !== sleepAnalysisType)
    : participantHealthData?.dataTypes;

  return {
    ...participantHealthData,
    dataTypes: filteredHealthDataTypes ?? [],
    executeSleepAnalysis: isSleepAnalysisIncluded,
  };
}

const formatStudyData = (study) => {
  const shouldIncludeSleepAnalysis = study?.participantHealthData?.executeSleepAnalysis;
  const participantData =
    study?.participantHealthData || defaultStudyInformation.participantHealthData;
  const dataTypes = shouldIncludeSleepAnalysis
    ? [...(participantData?.dataTypes ?? []), HK_SLEEP_ANALYSIS]
    : [];

  return {
    id: study.id,
    studyId: study.idNumber,
    studyName: study.name,
    studyDuration: study.duration,
    studyConsent: study.consent,
    participantHealthData: {
      ...participantData,
      dataTypes,
    },
    version: study.version || 1,
  };
};

const handleEditStudy = (study) => {
  const updatedStudyData = formatStudyData(study);

  studyData.value = { ...updatedStudyData };
  isEdition.value = true;
  tasks.value = tasksByStudy.value[study.id] || [];
};

const handleEditTask = (task) => {
  isTaskEdition.value = true;
  selectedTask.value = { ...task };
};

const handleExitEdition = () => {
  studyData.value = { ...defaultStudyInformation };
  tasks.value = [];
  isEdition.value = false;
};

async function handleDeleteTask(index, task) {
  if (isEdition.value) {
    const taskData = { ...task, deleted: true, edited: false };
    tasks.value = tasks.value.map((task) => (task.id === taskData.id ? taskData : task));
  } else {
    handleRemoveTask(index);
  }
  isTaskEdition.value = false;
}

async function updateStudyVersion() {
  const updatedStudyVersion = {
    ...studyData.value,
    version: studyData.value.version + 1,
  };
  try {
    await updateStudy(updatedStudyVersion);
  } catch (error) {
    toast.error('Error updating study version, please try again');
  }
}

async function handleUpdateTask(taskData) {
  tasks.value = tasks.value.map((task) => (task.id === taskData.id ? taskData : task));
}

async function saveTasksChanges() {
  const tasksToUpdate = tasks.value.filter((task) => task.edited || task.deleted);
  try {
    const tasksPromises = tasksToUpdate.map((task) => {
      if (task.deleted) {
        return deleteTask(task.id);
      }
      if (task.edited) {
        return updateTask(task);
      }
    });
    await Promise.all(tasksPromises);
    await updateStudyVersion();
    toast.success('The study was successfully updated');
    fetchTasks();
  } catch (error) {
    toast.error(error.message);
  } finally {
    handleExitEdition();
  }
}

async function handleSubmitRegister() {
  isLoadingSubmit.value = true;
  try {
    if (isEdition.value) {
      await saveTasksChanges();
      return;
    }

    const validateIfTheStudyAlreadyExists = existingStudies.value.some(
      (study) => study.idNumber === studyData.value.studyId
    );

    if (validateIfTheStudyAlreadyExists) {
      toast.error('Study Identifier already exists');
    } else if (tasks.value.length) {
      const studyId = uuidv4();
      const participantHealthData = prepareParticipantHealthData(
        studyData.value.participantHealthData,
        HK_SLEEP_ANALYSIS
      );

      await registerStudy(
        {
          ...studyData.value,
          participantHealthData,
        },
        studyId
      );

      await Promise.all(tasks.value.map(async (task) => await registerTask(task, studyId)));
      tasks.value = [];
      studyData.value = { ...defaultStudyInformation };
      toast.success('The study was successfully registered');
      fetchStudies();
      fetchTasks();
    } else {
      toast.warning('You must add tasks to continue');
    }
  } catch (error) {
    toast.error(error.message);
  } finally {
    isLoadingSubmit.value = false;
  }
}

const filteredStudies = computed(() => {
  if (!searchExistingStudy.value.trim()) {
    return existingStudies.value;
  }

  return existingStudies.value.filter((study) =>
    study.idNumber?.toLowerCase().includes(searchExistingStudy.value?.toLowerCase().trim())
  );
});

async function fetchStudies() {
  isLoading.value = true;
  try {
    const studies = await getStudies();
    existingStudies.value = studies;
    isLoading.value = false;
  } catch (error) {
    toast.error(error.message);
  } finally {
    isLoading.value = false;
  }
}

async function fetchTasks() {
  try {
    const tasks = await getTasks();
    const groupedTasks = tasks.reduce((acc, task) => {
      if (task.studyId) {
        if (!acc[task.studyId]) {
          acc[task.studyId] = [];
        }
        acc[task.studyId].push(task);
      }
      return acc;
    }, {});
    tasksByStudy.value = groupedTasks;
  } catch (error) {
    toast.error(error.message);
  }
}

onMounted(() => {
  fetchStudies();
  fetchTasks();
});
</script>

<style lang="scss" scoped>
.list-button {
  display: flex;
  justify-content: center;
  color: #fff;
  border: none;
  margin-right: 5px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

.wrapper-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  height: 100vh;
  width: 100%;

  .card-header {
    margin-bottom: 2rem;

    h1#title {
      font-size: 2rem;
    }

    span {
      font-size: 10pt;
    }

    @media screen and (max-width: 510px) {
      margin-bottom: 1rem;
    }
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;
    width: 50%;

    .form-group {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;

      label {
        text-align: start;
        margin-bottom: 3px;
        font-size: 10pt;
      }

      a {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    }

    button#b-form {
      margin-top: 1rem;
    }
  }
}

textarea {
  resize: vertical;
  min-height: 100px;
}

.task-list {
  margin-top: 10px;
  width: 100%;
  height: 150px;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  & p {
    color: #34455A;
  }

  & span {
    color: #34455A;
  }
}

.task {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}

.delete-button {
  box-shadow: none;
  width: 100%;
  display: flex;
  justify-content: center;
  color: #fff;
  border: none;
  margin-right: 5px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

.studiesModal {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100vh;
  background-color: #fff;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.studiesModal-content {
  padding: 10px 15px;

  & h2,
  span,
  label {
    color: #34455A;
  }

  & p {
    color: black;
  }

  & ul {
    padding: 0;
  }

  & li {
    position: relative;
    padding-left: 1.5em;
    list-style: none;
    padding-bottom: 15px;
    border-bottom: 1px solid #eee;

    &:before {
      content: '\25C6';
      position: absolute;
      left: 0;
      color: #34455A;
    }
  }

  & .search-input {
    width: 100%;
    padding: 10px;
    margin-top: 8px;
    margin-bottom: 40px;
    border-radius: 5px;
    border: 1px solid #ccc;
    text-align: center;
    cursor: pointer;
  }
}

.studiesModal-toggle-button {
  top: 30%;
  position: fixed;
  right: 0;
  background-color: #fff;
  border: none;
  color: #34455A;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
  box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
  transition: top 0.4s ease-in-out;

  & span {
    display: flex;
    gap: 1rem;
  }
}

.studiesModal-toggle-button.open {
  top: 3px;
  right: 0;
  z-index: 2;
  box-shadow: none;
}

.slide-enter-active,
.slide-leave-active {
  transition: right 0.5s ease-in-out;
}

.slide-enter-from {
  right: -300px;
}

.slide-leave-to {
  right: -360px;
}
</style>
