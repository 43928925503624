<template>
  <alt-date withCalendar :defaultDate="defaultDate" range @update:model-value="onDateChange" />
</template>

<script setup>
import AltDate from '@/components/calendar/AlternateDatePicker.vue';
const props = defineProps({
  defaultDate: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits(['dateChange']);

function onDateChange(value) {
  emits('dateChange', value);
}
</script>
