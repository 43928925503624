export const TranslateHealthKitData = {
  DomainFromDTO({
    id = '',
    effectivePeriod: { start: startDate = '', end: endDate = '' } = {},
    valueQuantity: { value = 0, unit = '' } = {},
    code: {
      coding: {
        0: {
          code: codeValue = '',
          display: displayName = '',
          system: systemName = 'com.apple.health',
        } = {},
      } = {},
    } = {},
    device: { display = '', reference = '', type = '' } = {},
  } = {}) {
    return {
      id: id ?? '',
      code: {
        codeValue: codeValue ?? '',
        displayName: displayName || codeValue || '',
        systemName: systemName || 'com.apple.health',
      },
      device: {
        display: display ?? '',
        reference: reference ?? '',
        type: type ?? '',
      },
      value: value ?? 0,
      unit: unit ?? '',
      startDate: startDate ?? '',
      endDate: endDate ?? '',
    };
  },

  DTOFromDomain({
    id = '',
    code: { codeValue = '', displayName = '', systemName = 'com.apple.health' } = {},
    value = 0,
    unit = '',
    startDate = '',
    endDate = '',
  } = {}) {
    return {
      id: id ?? '',
      effectivePeriod: {
        start: startDate ?? '',
        end: endDate ?? '',
      },
      valueQuantity: {
        value: value ?? 0,
        unit: unit ?? '',
      },
      code: {
        coding: [
          {
            code: codeValue ?? '',
            display: displayName || codeValue || '',
            system: systemName || 'com.apple.health',
          },
        ],
      },
    };
  },
};
