export const GRAPH_TYPES = {
  LINE: 'line',
  SCATTER: 'scatter',
  RANGE: 'range',
  BAR: 'bar',
};

export const COLUMNS_CSV_WITH_HEALTH_KIT_DATA = [
  'Patient ID',
  'Category Type',
  'Date',
  'Value',
  'Device',
  'Unit',
];

export const HK_CATEGORIES = {
  Activity: {
    logo: require('@/assets/icons/Flame.png'),
    dataType: [
      {
        key: 'HKQuantityTypeIdentifierStepCount',
        name: 'Step Count',
        color: '#FF4500',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDistanceWalkingRunning',
        name: 'Walking/Running Distance',
        color: '#FF6347',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierActiveEnergyBurned',
        name: 'Active Energy Burned',
        color: '#FF7F50',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierBasalEnergyBurned',
        name: 'Basal Energy Burned',
        color: '#FFA07A',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierFlightsClimbed',
        name: 'Flights Climbed',
        color: '#FF8C00',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierAppleExerciseTime',
        name: 'Exercise Time',
        color: '#FF4500',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierAppleStandTime',
        name: 'Stand Time',
        color: '#FF6347',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierDistanceCycling',
        name: 'Cycling Distance',
        color: '#FF7F50',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierDistanceSwimming',
        name: 'Swimming Distance',
        color: '#4682B4',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierDistanceWheelchair',
        name: 'Wheelchair Distance',
        color: '#20B2AA',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierPushCount',
        name: 'Push Count (Wheelchair)',
        color: '#FF4500',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDistanceDownhillSnowSports',
        name: 'Downhill Snow Sports Distance',
        color: '#00BFFF',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierSwimmingStrokeCount',
        name: 'Swimming Stroke Count',
        color: '#1E90FF',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKWorkoutTypeIdentifier',
        name: 'Workout',
        color: '#FF4500',
        typeChart: GRAPH_TYPES.SCATTER,
      },
    ],
  },
  Body: {
    logo: require('@/assets/icons/Body.png'),
    dataType: [
      {
        key: 'HKQuantityTypeIdentifierBodyMassIndex',
        name: 'Body Mass Index (BMI)',
        color: '#4682B4',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierBodyFatPercentage',
        name: 'Body Fat Percentage',
        color: '#5F9EA0',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierBodyMass',
        name: 'Body Mass',
        color: '#6495ED',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierLeanBodyMass',
        name: 'Lean Body Mass',
        color: '#87CEEB',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierWaistCircumference',
        name: 'Waist Circumference',
        color: '#B0E0E6',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierHeight',
        name: 'Height',
        color: '#AFEEEE',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierBasalBodyTemperature',
        name: 'Basal Body Temperature',
        color: '#B0C4DE',
        typeChart: GRAPH_TYPES.LINE,
      },
    ],
  },
  Hearing: {
    logo: require('@/assets/icons/Hearing.png'),
    dataType: [
      {
        key: 'HKQuantityTypeIdentifierEnvironmentalAudioExposure',
        name: 'Environmental Audio Exposure',
        color: '#8A2BE2',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierHeadphoneAudioExposure',
        name: 'Headphone Audio Exposure',
        color: '#9370DB',
        typeChart: GRAPH_TYPES.LINE,
      },
    ],
  },
  Heart: {
    logo: require('@/assets/icons/Heart.png'),
    dataType: [
      {
        key: 'HKQuantityTypeIdentifierHeartRate',
        name: 'Heart Rate',
        color: '#FF0000',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierRestingHeartRate',
        name: 'Resting Heart Rate',
        color: '#DC143C',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierWalkingHeartRateAverage',
        name: 'Walking Heart Rate Average',
        color: '#B22222',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierHeartRateVariabilitySDNN',
        name: 'Heart Rate Variability (SDNN)',
        color: '#CD5C5C',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKDataTypeIdentifierElectrocardiogram',
        name: 'Electrocardiogram (ECG)',
        color: '#8B0000',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierBloodPressureSystolic',
        name: 'Systolic Blood Pressure',
        color: '#FF6347',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierBloodPressureDiastolic',
        name: 'Diastolic Blood Pressure',
        color: '#FF4500',
        typeChart: GRAPH_TYPES.LINE,
      },
    ],
  },
  Mindfulness: {
    logo: require('@/assets/icons/Mindfulness.png'),
    dataType: [
      {
        key: 'HKCategoryTypeIdentifierMindfulSession',
        name: 'Mindful Session',
        color: '#8FBC8F',
        typeChart: GRAPH_TYPES.SCATTER,
      },
    ],
  },
  Mobility: {
    logo: require('@/assets/icons/Mobility.png'),
    dataType: [
      {
        key: 'HKQuantityTypeIdentifierSixMinuteWalkTestDistance',
        name: 'Six-Minute Walk Test Distance',
        color: '#32CD32',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierWalkingSpeed',
        name: 'Walking Speed',
        color: '#3CB371',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierWalkingDoubleSupportPercentage',
        name: 'Walking Double Support Percentage',
        color: '#2E8B57',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierWalkingAsymmetryPercentage',
        name: 'Walking Asymmetry Percentage',
        color: '#66CDAA',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierWalkingStepLength',
        name: 'Walking Step Length',
        color: '#20B2AA',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierStairAscentSpeed',
        name: 'Stair Ascent Speed',
        color: '#228B22',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierStairDescentSpeed',
        name: 'Stair Descent Speed',
        color: '#008000',
        typeChart: GRAPH_TYPES.LINE,
      },
    ],
  },
  Nutrition: {
    logo: require('@/assets/icons/Nutrition.png'),
    dataType: [
      {
        key: 'HKQuantityTypeIdentifierDietaryEnergyConsumed',
        name: 'Dietary Energy Consumed',
        color: '#FFD700',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryFatTotal',
        name: 'Total Fat',
        color: '#FFA500',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryFatSaturated',
        name: 'Saturated Fat',
        color: '#FF8C00',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryFatPolyunsaturated',
        name: 'Polyunsaturated Fat',
        color: '#DAA520',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryFatMonounsaturated',
        name: 'Monounsaturated Fat',
        color: '#F4A460',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryCholesterol',
        name: 'Cholesterol',
        color: '#D2691E',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietarySodium',
        name: 'Sodium',
        color: '#FF7F50',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryCarbohydrates',
        name: 'Carbohydrates',
        color: '#FF6347',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryFiber',
        name: 'Dietary Fiber',
        color: '#FF4500',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietarySugar',
        name: 'Sugar',
        color: '#FFA07A',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryProtein',
        name: 'Protein',
        color: '#FF8C00',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryVitaminA',
        name: 'Vitamin A',
        color: '#FFD700',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryVitaminB6',
        name: 'Vitamin B6',
        color: '#FFA500',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryVitaminB12',
        name: 'Vitamin B12',
        color: '#FF4500',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryVitaminC',
        name: 'Vitamin C',
        color: '#FF6347',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryVitaminD',
        name: 'Vitamin D',
        color: '#FFD700',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryVitaminE',
        name: 'Vitamin E',
        color: '#FFA07A',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryVitaminK',
        name: 'Vitamin K',
        color: '#FF8C00',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryCalcium',
        name: 'Calcium',
        color: '#FF7F50',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryIron',
        name: 'Iron',
        color: '#FFA500',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryMagnesium',
        name: 'Magnesium',
        color: '#FFD700',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryPotassium',
        name: 'Potassium',
        color: '#FF4500',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryFolate',
        name: 'Folate',
        color: '#FF6347',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryNiacin',
        name: 'Niacin',
        color: '#FFD700',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryRiboflavin',
        name: 'Riboflavin',
        color: '#FF4500',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryThiamin',
        name: 'Thiamin',
        color: '#FFA07A',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryZinc',
        name: 'Zinc',
        color: '#FF6347',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryCopper',
        name: 'Copper',
        color: '#FF4500',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryManganese',
        name: 'Manganese',
        color: '#FFD700',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietarySelenium',
        name: 'Selenium',
        color: '#FFA500',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryBiotin',
        name: 'Biotin',
        color: '#FF8C00',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryPantothenicAcid',
        name: 'Pantothenic Acid',
        color: '#FFD700',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryPhosphorus',
        name: 'Phosphorus',
        color: '#FF6347',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryIodine',
        name: 'Iodine',
        color: '#FF4500',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryChromium',
        name: 'Chromium',
        color: '#FFA500',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryMolybdenum',
        name: 'Molybdenum',
        color: '#FFD700',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryChloride',
        name: 'Chloride',
        color: '#FF4500',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryCaffeine',
        name: 'Caffeine',
        color: '#FF6347',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierDietaryWater',
        name: 'Water',
        color: '#00CED1',
        typeChart: GRAPH_TYPES.BAR,
      },
    ],
  },
  Respiratory: {
    logo: require('@/assets/icons/Respiratory.png'),
    dataType: [
      {
        key: 'HKQuantityTypeIdentifierRespiratoryRate',
        name: 'Respiratory Rate',
        color: '#4682B4',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierOxygenSaturation',
        name: 'Oxygen Saturation',
        color: '#5F9EA0',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierInhalerUsage',
        name: 'Inhaler Usage',
        color: '#87CEEB',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKQuantityTypeIdentifierPeakExpiratoryFlowRate',
        name: 'Peak Expiratory Flow Rate',
        color: '#00BFFF',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierForcedVitalCapacity',
        name: 'Forced Vital Capacity',
        color: '#1E90FF',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierForcedExpiratoryVolume1',
        name: 'Forced Expiratory Volume (1 sec)',
        color: '#4682B4',
        typeChart: GRAPH_TYPES.LINE,
      },
    ],
  },
  Sleep: {
    logo: require('@/assets/icons/Sleep.png'),
    dataType: [
      {
        key: 'HKCategoryValueSleepAnalysisInBed',
        name: 'Sleep Analysis In Bed',
        color: '#191970',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKCategoryValueSleepAnalysisAwake',
        name: 'Sleep Analysis Awake',
        color: '#191970',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKCategoryValueSleepAnalysisAsleepCore',
        name: 'Sleep Analysis Core',
        color: '#191970',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKCategoryValueSleepAnalysisAsleepDeep',
        name: 'Sleep Analysis Deep',
        color: '#191970',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKCategoryValueSleepAnalysisAsleepREM',
        name: 'Sleep Analysis Asleep REM',
        color: '#191970',
        typeChart: GRAPH_TYPES.LINE,
      },
    ],
  },
  Symptoms: {
    logo: require('@/assets/icons/Symtoms.png'),
    dataType: [
      {
        key: 'HKCategoryTypeIdentifierSymptoms',
        name: 'Symptoms',
        color: '#B22222',
        typeChart: GRAPH_TYPES.SCATTER,
      },
      {
        key: 'HKCategoryTypeIdentifierAppleWalkingSteadinessEvent',
        name: 'Apple Walking Steadiness Event',
        color: '#8B0000',
        typeChart: GRAPH_TYPES.SCATTER,
      },
    ],
  },
  Vitals: {
    logo: require('@/assets/icons/Vitals.png'),
    dataType: [
      {
        key: 'HKQuantityTypeIdentifierBloodGlucose',
        name: 'Blood Glucose',
        color: '#FF4500',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierBloodPressureSystolic',
        name: 'Systolic Blood Pressure',
        color: '#FF6347',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierBloodPressureDiastolic',
        name: 'Diastolic Blood Pressure',
        color: '#FF4500',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierBodyTemperature',
        name: 'Body Temperature',
        color: '#FFD700',
        typeChart: GRAPH_TYPES.LINE,
      },
      {
        key: 'HKQuantityTypeIdentifierRespiratoryRate',
        name: 'Respiratory Rate',
        color: '#4682B4',
        typeChart: GRAPH_TYPES.LINE,
      },
    ],
  },
  'Other Data': {
    logo: require('@/assets/icons/Other.png'),
    dataType: [
      {
        key: 'HKCategoryTypeIdentifierSexualActivity',
        name: 'Sexual Activity',
        color: '#FF69B4',
        typeChart: GRAPH_TYPES.SCATTER,
      },
      {
        key: 'HKCategoryTypeIdentifierToothbrushingEvent',
        name: 'Toothbrushing Event',
        color: '#FFB6C1',
        typeChart: GRAPH_TYPES.SCATTER,
      },
      {
        key: 'HKCategoryTypeIdentifierUVExposure',
        name: 'UV Exposure',
        color: '#FFD700',
        typeChart: GRAPH_TYPES.LINE,
      },
    ],
  },
  Surveys: {
    logo: require('@/assets/icons/Other.png'),
    dataType: [
      {
        key: 'HKCategoryTypeIdentifierMindfulSession',
        name: 'Mindful Session',
        color: '#8FBC8F',
        typeChart: GRAPH_TYPES.SCATTER,
      },
      {
        key: 'HKCategoryTypeIdentifierSleepAnalysis',
        name: 'Sleep Analysis',
        color: '#191970',
        typeChart: GRAPH_TYPES.BAR,
      },
      {
        key: 'HKCategoryTypeIdentifierSymptoms',
        name: 'Symptoms',
        color: '#B22222',
        typeChart: GRAPH_TYPES.SCATTER,
      },
    ],
  },
};

export const HK_CATEGORIES_SLEEP_ANALYSIS = [
  'HKCategoryValueSleepAnalysisInBed',
  'HKCategoryValueSleepAnalysisAwake',
  'HKCategoryValueSleepAnalysisAsleepCore',
  'HKCategoryValueSleepAnalysisAsleepDeep',
  'HKCategoryValueSleepAnalysisAsleepREM',
];

export const TYPE_COLLECTION = {
  STATS: 'stats',
  SLEEP_ANALYSIS: 'sleepAnalysis',
};