const userRolesBasePath = `users_roles`;
const usersRolesPaths = {
  list: userRolesBasePath,
  get: (userId) => `${userRolesBasePath}/${userId}`,
};

const studyBasePath = `studies`;
const studiesPaths = {
  list: studyBasePath,
  get: (studyId) => `${studyBasePath}/${studyId}`,
};

const healthCollectionOptionsBasePath = `healthCollectionOptions`;
const healthCollectionOptionsPaths = {
  list: healthCollectionOptionsBasePath,
  get: (healthCollectionOptionId) =>
    `${healthCollectionOptionsBasePath}/${healthCollectionOptionId}`,
};

const surveyBasePath = `surveys`;
const surveysPaths = {
  list: surveyBasePath,
};

const exerciseBasePath = `exercises`;
const exercisesPaths = {
  list: exerciseBasePath,
};

const tasksBasePath = `tasks`;
const tasksPaths = {
  list: tasksBasePath,
};

const surveyAnswersBasePath = `taskAnswerOptions`;
const surveyAnswersPaths = {
  list: surveyAnswersBasePath,
};

const completedTasksBasePath = `CompletedTasks`;
const usersBasePath = `users`;
const usersPaths = {
  listAll: usersBasePath,
  baseCompletedTasks: completedTasksBasePath,
  completedTasks: (userId) => `${usersBasePath}/${userId}/${completedTasksBasePath}`,
};

const healthKitBasePath = (studyId, userId) =>
  `studies/${studyId}/${usersBasePath}/${userId}/healthKit`;
const healthKitPaths = {
  list: (studyId, userId) => healthKitBasePath(studyId, userId),
};

const metricsBasePath = (studyId, userId) =>
  `studies/${studyId}/${usersBasePath}/${userId}/metrics`;
const metricsPaths = {
  list: (studyId, userId) => metricsBasePath(studyId, userId),
};

export {
  usersRolesPaths,
  studiesPaths,
  surveysPaths,
  exercisesPaths,
  usersPaths,
  healthKitPaths,
  metricsPaths,
  tasksPaths,
  surveyAnswersPaths,
  healthCollectionOptionsPaths,
};
