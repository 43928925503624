<template>
  <div @click="handleCardSelection" class="card" v-if="formattedName">
    <div class="card-header">
      <div class="card-header__title">
        <h4 :style="{ color: colorTitle }">
          <strong>{{ formattedName }}</strong>
        </h4>
      </div>
      <div class="card-header__logo" v-if="logo">
        <img :src="logo" width="50" alt="Activity Card Logo" />
      </div>
    </div>
    <div class="card-body">
      <span class="card-date">{{ formattedDate }}</span>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue';

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  code: {
    type: Object,
    required: true,
  },
  startDate: {
    type: String,
    required: true,
  },
  endDate: {
    type: String,
    required: true,
  },
  logo: String,
  colorTitle: {
    type: String,
    default: '#000',
  },
});

const emit = defineEmits(['cardSelected']);

const formattedName = computed(() => props.code?.nameToShow?.name || props.code.displayName);

const formattedDate = computed(() => {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  const start = new Date(props.startDate).toLocaleDateString('en-US', options);
  const end = new Date(props.endDate).toLocaleDateString('en-US', options);
  return `${start} - ${end}`;
});

const handleCardSelection = () => {
  emit('cardSelected', { id: props.id });
};
</script>

<style lang="scss" scoped>
.card {
  width: 13rem;
  min-width: 100%;
  padding: 0 0.7rem 0.7rem;
  border-radius: 5px;
  background-color: $bg-card;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 1rem;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
      h4 {
        margin: 0;
      }
    }

    &__logo {
      img {
        object-fit: scale-down;
        height: 60px;
        width: 50px;
      }
    }
  }

  .card-body {
    &__info {
      display: flex;
      align-items: baseline;
      gap: 1px;

      .info-value {
        font-size: 17pt;
        font-weight: 700;
        text-transform: uppercase;
      }

      .info-measure {
        color: lightslategray;
        font-weight: bold;
        font-size: 10pt;
      }
    }

    .card-date {
      display: block;
      color: #555;
    }
  }
}
</style>
