import { ref } from 'vue';
import { defineStore } from 'pinia';
import request from '@/Rest';
import { usersRolesPaths } from '@/common/static_data/api_routes';
import { auth } from '@/plugins/firebase/firebase';
import { ROLES } from '@/common/static_data/constants';

const fetchUserRoleData = async (userId) => {
  return await request.GET(usersRolesPaths.get(userId)).Execute();
};

export const useUserRoleStore = defineStore('userRoleStore', () => {
  const userRole = ref(null);
  const userId = ref(null);

  const resetUserRole = () => {
    userRole.value = null;
    userId.value = null;
  };

  const getUserRole = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No user logged in');
      }

      const userSnap = await fetchUserRoleData(user.uid);
      if (userSnap) {
        userRole.value = ROLES.SUPER_ADMIN;
        userId.value = user.uid;
      }
    } catch (error) {
      console.error('Error fetching user role:', error);
      throw error;
    }
  };

  return {
    userRole,
    userId,
    resetUserRole,
    getUserRole,
  };
});