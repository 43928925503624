<template>
  <div class="center">
    <pulse-loader
      :color="color"
      :size="size"
    ></pulse-loader>
  </div>
</template>
<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import RotateLoader from "vue-spinner/src/RotateLoader.vue";
import FadeLoader from "vue-spinner/src/FadeLoader.vue";
import DotLoader from "vue-spinner/src/DotLoader.vue";
export default {
  name: "loading",
  components: {
    PulseLoader,
    RotateLoader,
    FadeLoader,
    DotLoader,
  },
  props: {
    size: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "#34455A"
    }
  },
  mounted() {
    if (this.size) {
      this._size = this.size;
    }
  },
};
</script>
<style scoped>
.center {
  margin: auto;
  width: 85%;
}
</style>
