<template>
  <form class="login-form" @submit.prevent="handleForgotPassword">
    <div v-if="errorMessage" class="auth-error">
      <p class="mb-1 subtitle">{{ errorMessage }}</p>
    </div>
    <div class="form-group mt-5">
      <label for="email">Email Address</label>
      <input type="email" placeholder="emailaddress@example.com" id="email" v-model="email" />
    </div>
    <div class="flex space-between">
      <button class="btn btn-ck w-50 m-auto" type="button" @click="handleCancelEvent">
        Cancel
      </button>
      <button class="btn btn-ck w-50 m-auto" type="submit">
        <loading-icon :color="'white'" v-if="loadingRecover" size="6px" class="w-100"/>
        <span v-else>Ok</span>
      </button>
    </div>
  </form>
</template>

<script setup>
import { ref } from "vue";
import { toast } from 'vue3-toastify';
import { ForgotPassword } from "../services/auth";
import loadingIcon from "@/components/loading";

const props = defineProps({
  forgotPassword: Boolean
})

const emit = defineEmits(['toggleForgotPasswordForm'])

const email = ref("");
const errorMessage = ref("")
const loadingRecover = ref(false)

async function handleForgotPassword() {
  try {
    loadingRecover.value = true;
    await ForgotPassword(email.value)
    errorMessage.value = ""
    toast.success('Email to recover password has been successfully sent')
    emit('toggleForgotPasswordForm')
  } catch (error) {
    errorMessage.value = error.message
  } finally {
    loadingRecover.value = false
  }
}

function handleCancelEvent() {
  emit('toggleForgotPasswordForm')
  if (errorMessage.value) {
    errorMessage.value = ""
  }
}
</script>

<style lang="scss" scoped>
.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem;

  .form-group {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;

    label {
      text-transform: uppercase;
      text-align: start;
      margin-bottom: 3px;
      font-size: 10pt;
      color: #6c757d
    }

    input {
      margin-bottom: 0.5rem;
      font-size: 1em;
      border: 0;
      border-bottom: 1px solid #6c757d;
      -webkit-appearance: none;
      border-radius: 0;
      padding: 0;
      cursor: text;
    }

    :focus {
      outline: 0;
      border-bottom: 1px solid #B61440;
    }

    a {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.auth-error {
  margin-bottom: 0.5rem;
  display: grid;
  gap: 10px;
  margin-top: 0;
  text-align: center;
  color: red;
}

.space-between {
  gap: 5rem;
}
</style>