<template>
  <div class="statistic-wrapper">
    <Title :title="dataType.name" />
    <DatePicker :defaultDate="dateRange" @dateChange="onDateChange" />
    <Charts
      :graphType="graphType"
      :series="formattedData"
      :labels="chartLabels"
      :hkCode="hkCode"
      :isTooltipCustom="isTooltipCustom"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import Title from '@/healthKit/atoms/Title.vue';
import DatePicker from '@/healthKit/atoms/DatePicker.vue';
import Charts from '@/healthKit/molecules/Charts.vue';

const props = defineProps({
  dataType: {
    type: Object,
    required: true,
  },
  dateRange: {
    type: Object,
    required: true,
  },
  formattedData: {
    type: Array,
    required: true,
  },
  chartLabels: {
    type: Array,
    default: () => [],
  },
  hkCode: {
    type: String,
    required: true,
  },
  isTooltipCustom: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emits = defineEmits(['dateChange']);

const graphType = computed(() => props.dataType.typeChart);

function onDateChange(value) {
  emits('dateChange', value);
}
</script>
