<template>
  <section>
    <button
      class="form-input w-100 addButton"
      id="surveyQuestions"
      type="button"
      @click="toggleRegisterItemsModal"
      required
    >
      Add New Item <b>+</b>
    </button>
    <modal
      title="Register Item"
      :maxWidth="'450px'"
      :showModal="isTogglingItemsModal"
      @closeModal="toggleRegisterItemsModal"
    >
      <template v-slot:content>
        <form class="form" @submit.prevent="handleSubmitRegister">
          <section class="form-group m-2">
            <label for="questionType" class="flex text-muted mt-3"> Item Type </label>
            <select class="form-select mt-1" id="questionType" v-model="newItem.type" required>
              <option v-for="itemType in questionnaireItemTypes" :value="itemType">
                {{ capitalize(itemType) }}
              </option>
            </select>

            <div v-if="newItem.type === 'group' || newItem.type === 'display'" class="mt-3">
              <label for="textItem" class="flex text-muted">Text</label>
              <input
                autocomplete="off"
                class="w-100 form-input mt-1"
                type="text"
                placeholder="Some text"
                id="textItem"
                v-model="newItem.value"
                required
              />
            </div>
            <div v-else class="mt-3">
              <label for="questionItem" class="flex text-muted">Question</label>
              <input
                autocomplete="off"
                class="w-100 form-input mt-1"
                type="text"
                placeholder="Ex: What is your age?"
                id="questionItem"
                v-model="newItem.value"
                required
              />
            </div>
            <SwitchInput
              v-if="questionnaireItemTypesWithoutGroup.includes(newItem.type)"
              v-model="newItem.required"
              label="Answer Required:"
            />
          </section>

          <section v-if="newItem.type === 'group'" class="form-group m-2">
            <label for="groupItems" class="flex text-muted">Group Items</label>
            <button
              class="form-input w-100 mt-1 addButton"
              id="groupItems"
              type="button"
              @click="toggleGroupItemsModal"
            >
              Add New Group Item <b>+</b>
            </button>
          </section>

          <ul class="options-list h-150" v-if="newItem.type === 'group' && groupItems?.length">
            <li v-for="(groupItem, index) in groupItems" :key="`item-${index}-${groupItem}`">
              <div class="ml-2">
                <span class="flex justify-center mb-2">
                  Item {{ index + 1 }} - Type {{ groupItem.type }}
                </span>
                <p class="break-words-smaller">
                  {{ groupItem.value }}
                </p>
              </div>
              <div>
                <button
                  class="btn btn-ck delete-button fill-danger"
                  type="button"
                  @click="removeGroupItem(index)"
                >
                  Delete
                </button>
              </div>
            </li>
          </ul>

          <section
            v-if="questionnaireItemsWithOptions.includes(newItem.type)"
            class="form-group m-2"
          >
            <label for="answerOptions" class="flex text-muted"> Answer Options (Min. 2) </label>
            <button
              class="form-input w-100 mt-1 addButton"
              type="button"
              id="answerOptions"
              @click="toggleAddOptionsModal"
            >
              Add New Answer Option <b>+</b>
            </button>
          </section>

          <ul v-if="newItem.options?.length" class="options-list h-150">
            <li v-for="(option, index) in newItem.options" :key="`option-${index}-${option}`">
              <div class="ml-2">
                <span class="flex justify-center mb-2">Option {{ index + 1 }}</span>
                <p class="break-words-smaller">
                  {{ option?.display ?? option }}
                </p>
              </div>
              <div>
                <button
                  type="button"
                  class="btn btn-ck delete-button fill-danger"
                  @click="handleRemoveOption(index)"
                >
                  Delete
                </button>
              </div>
            </li>
          </ul>

          <section class="flex gap-3 mt-5 w-100">
            <button class="btn btn-ck m-auto" type="submit">Register Question</button>
          </section>
        </form>
      </template>
    </modal>
  </section>

  <register-item-option
    :item="newItem"
    :isTogglingOptionsModal="isTogglingOptionsModal"
    @add-item-option="handleAddItemOption"
    @toggle-add-options-modal="toggleAddOptionsModal"
  />

  <register-group-item
    :item="newGroupItem"
    :groupItems="groupItems"
    :isTogglingGroupItemsModal="isTogglingGroupItemsModal"
    @add-new-group-item="handleAddNewGroupItem"
    @add-group-item-option="handleAddGroupItemOption"
    @reset-new-group-item="resetNewGroupItem"
    @remove-group-option="handleRemoveGroupOption"
    @toggle-group-items-modal="toggleGroupItemsModal"
  />
</template>

<script setup>
import { ref, watch } from 'vue';
import { toast } from 'vue3-toastify';
import { v4 as uuidv4 } from 'uuid';

import {
  questionnaireItemTypes,
  capitalize,
  questionnaireItemsWithOptions,
  questionnaireItemTypesWithoutGroup,
  isCodingTypeElementWithAtLeastTwoOptions,
} from '@/surveys/utils';

import Modal from '@/components/Modal';
import RegisterItemOption from '@/surveys/atoms/RegisterItemOption';
import RegisterGroupItem from '@/surveys/atoms/RegisterGroupItem';
import SwitchInput from '@/components/SwitchInput.vue';

const emit = defineEmits(['addSurveyItem']);

const isTogglingItemsModal = ref(false);
const isTogglingOptionsModal = ref(false);
const isTogglingGroupItemsModal = ref(false);

const newItem = ref({
  value: '',
  type: 'text',
  required: false,
  options: [],
});

const newGroupItem = ref({
  value: '',
  type: 'text',
  options: [],
});

const groupItems = ref([]);

function resetNewItem() {
  newItem.value = { value: '', type: 'text', options: [] };
}

function resetNewGroupItem() {
  newGroupItem.value = { value: '', type: 'text', options: [] };
}

function resetGroupItems() {
  groupItems.value = [];
}

function toggleRegisterItemsModal() {
  isTogglingItemsModal.value = !isTogglingItemsModal.value;
}

function toggleGroupItemsModal() {
  isTogglingGroupItemsModal.value = !isTogglingGroupItemsModal.value;
}

function toggleAddOptionsModal() {
  isTogglingOptionsModal.value = !isTogglingOptionsModal.value;
}

const handleAddItemOption = (option) => {
  newItem.value.options.push(option);
};

const handleAddNewGroupItem = (item) => groupItems.value.push({ ...item });

const handleAddGroupItemOption = (item) => newGroupItem.value.options.push(item);

const handleRemoveOption = (index) => newItem.value.options.splice(index, 1);

const handleRemoveGroupOption = (index) => newGroupItem.value.options.splice(index, 1);

function removeGroupItem(index) {
  groupItems.value.splice(index, 1);
}

function handleSubmitRegister() {
  if (isCodingTypeElementWithAtLeastTwoOptions(newItem.value)) {
    toast.error('Add at least two options to continue');
  } else {
    let itemToEmit;
    const itemId = uuidv4();

    if (newItem.value.type === 'group') {
      itemToEmit = {
        text: newItem.value.value,
        type: newItem.value.type,
        item: groupItems.value.map((subItem) => ({
          linkId: itemId,
          text: subItem.value,
          type: subItem.type,
          required: subItem.required,
          options: subItem.options ?? [],
        })),
      };
    } else {
      itemToEmit = {
        linkId: itemId,
        text: newItem.value.value,
        type: newItem.value.type,
        required: newItem.value.required,
        options: newItem.value.options ?? [],
      };
    }

    emit('addSurveyItem', itemToEmit);
    resetNewItem();
    resetNewGroupItem();
    resetGroupItems();
    toggleRegisterItemsModal();
  }
}

watch(
  () => newItem.value.type,
  (newItemTypeValue) => {
    if (newItemTypeValue) {
      newItem.value.options = [];
    }
  }
);
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;

  .form-group {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;

    label {
      width: fit-content;
      text-align: start;
      margin-bottom: 3px;
      font-size: 10pt;
    }

    a {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  button#b-form {
    margin-top: 1rem;
  }
}

.delete-button {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #fff;
  border: none;
  margin-right: 5px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}
</style>
