import request from '@/Rest';
import { surveysPaths, surveyAnswersPaths } from '@/common/static_data/api_routes';
import { v4 as uuidv4 } from 'uuid';

export async function getSurveys() {
  try {
    const data = await request.GET(surveysPaths.list).Execute();
    const surveys = data.docs.map((element) => ({
      ...element.data(),
      id: element.id,
    }));
    return surveys;
  } catch {
    throw new Error('Error while fetching studies');
  }
}

export async function registerSurvey(Questionnaire, surveyId) {
  try {
    const data = {
      id: surveyId,
      name: Questionnaire.title,
      JsonQuestionnaire: Questionnaire,
      isActive: true,
    };

    await request.POST(`${surveysPaths.list}/${surveyId}`, { data }).Execute();
  } catch {
    console.error('Error while registering survey:', error.message);
    throw new Error('Error while registering survey');
  }
}

export async function deleteSurvey(surveyId) {
  try {
    await request.DELETE(`${surveysPaths.list}/${surveyId}`).Execute();
  } catch {
    throw new Error('Error while deleting survey');
  }
}

export async function getSurveyAnswerOptions(term) {
  if (!term) return {};
  const endTerm = term.replace(/.$/, (c) => String.fromCharCode(c.charCodeAt(0) + 1));
  try {
    const data = await request
      .GET(surveyAnswersPaths.list)
      .WHERE(['displayLowercase', '>=', term])
      .WHERE(['displayLowercase', '<', endTerm])
      .LIMIT(20)
      .Execute();
    const surveys = data.docs.map((element) => {
      const elementData = element.data();
      return {
        ...elementData,
        label: elementData.display,
        value: elementData.display,
      };
    });
    return surveys;
  } catch {
    throw new Error('Error while fetching survey answers');
  }
}

export function generateFHIRQuestionnaireStructure(survey, surveyId) {
  const questionnaire = {
    resourceType: 'Questionnaire',
    status: 'active',
    title: survey.name,
    publisher: 'Alternova',
    url: `https://mskapp-prod.web.app/register-studies/${surveyId}`,
    useContext: [
      {
        code: {
          system: 'http://hl7.org/fhir/ValueSet/usage-context-type',
          code: 'focus',
        },
        valueCodeableConcept: {
          coding: [{ system: 'http://hl7.org/fhir', display: 'Survey Focus' }],
        },
      },
    ],
    contact: [{ name: 'https://www.alternova.co/' }],
    item: [],
  };

  survey.items.forEach((questionnaireItem) => {
    const item = {
      linkId: uuidv4(),
      text: questionnaireItem.text ?? questionnaireItem.value,
      type: questionnaireItem.type,
      required: questionnaireItem.required ?? false,
    };

    if (questionnaireItem.options && questionnaireItem.options.length > 0) {
      if (questionnaireItem.type === 'choice') {
        item.answerOption = questionnaireItem.options.map((option) => {
          const valueCoding =
            typeof option === 'object' ? option : { code: uuidv4(), display: option };
          return { valueCoding };
        });
      } else if (questionnaireItem.type === 'decimal') {
        item.answerOption = questionnaireItem.options.map((option) => ({
          valueDecimal: option,
        }));
      }
    }

    if (questionnaireItem.type === 'group' && questionnaireItem.item) {
      item.item = questionnaireItem.item.map((subItem) => {
        const groupItem = {
          linkId: uuidv4(),
          text: subItem.text ?? subItem.value,
          type: subItem.type,
          required: subItem.required ?? false,
        };

        if (subItem.options && subItem.options.length > 0) {
          groupItem.answerOption = subItem.options.map((option) => {
            const valueCoding =
              typeof option === 'object' ? option : { code: uuidv4(), display: option };
            return { valueCoding };
          });
        }

        return groupItem;
      });
    }

    questionnaire.item.push(item);
  });

  return questionnaire;
}
