<template>
  <div class="page">
    <h1 class="mb-5 blue">Patients</h1>
    <alt-table :columns="columns" pagination :paginationOptions="paginationOptions" @onPagination="handlePagination">
      <template #t-row>
        <tr v-for="(patient, index) in getPageItems" :key="patient.id">
          <td>{{ index + 1}}</td>
          <td>
            <div class="flex justify-center">
              {{ patient.name || 'NN' }}
            </div>
          </td>
          <td>
            <div class="flex justify-center">
              {{ patient.id || 'NN' }}
            </div>
          </td>
          <td class="flex justify-center">
            <loading-icon class="flex justify-center" v-if="loadingId === patient.id" size="5px" />
            <span v-else class="btn-view pointer" @click="handleSelectPatient(patient.id, patient.name)">
              View
            </span>
          </td>
        </tr>
      </template>
    </alt-table>
  </div>
</template>

<script setup>
import altTable from '@/components/tables/altTable';
import loadingIcon from "@/components/loading";
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useOptions } from '@/components/common/paginationOptions';

const props = defineProps({
  patients: {
    type: Array,
    required: true
  },
  studyId: {
    type: String,
    required: true
  }
})
const route = useRoute();
const router = useRouter();
const columns = [{ header: 'N°' }, { header: 'Name' }, { header: 'Identifier' }, { header: 'Action' }]
const loadingId = ref(0)

const { paginationOptions, getPageItems, handlePagination } = useOptions(props.patients)
function handleSelectPatient(patientId, patientName) {
  loadingId.value = patientId
  router.push({
    name: 'patientStudy',
    params: { studyId: route.params.studyId, patientId },
    query: { patientName }
  })
}
</script>

<style lang="scss" scoped>
.blue {
  color: #34455A;
}

.btn-view {
  padding: 0.4rem 1.8em;
  background: #34455A;
  color: white;
}
</style>