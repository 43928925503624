<template>
  <study-list :handleSelectStudy="handleSelectStudy" />
</template>

<script setup>
import { useRouter } from 'vue-router';
import StudyList from '@/studies/organisms/studiesList.vue';
import { ROUTES } from '@/router';
import { toast } from 'vue3-toastify';

const router = useRouter();

const handleSelectStudy = async (study = { id: '', hasUsers: false }) => {
  if (!study.hasUsers) {
    toast.info('This study no has users associated, please select another study');
    return;
  }
  await router.push(`/${ROUTES.STUDY.LIST}/${study.id}`);
};
</script>
