<template>
  <div class="checkbox-wrapper-2">
    <label for="required" class="flex text-muted">
      {{ props.label }}
    </label>
    <input
      type="checkbox"
      id="required"
      class="ikxBAC sc-gJwTLC"
      :checked="props.modelValue"
      @change="onchange"
    />
  </div>
</template>

<script setup>
const props = defineProps(['modelValue', 'label']);
const emit = defineEmits(['update:modelValue']);

const onchange = () => {
  emit('update:modelValue', !props.modelValue);
};
</script>

<style lang="scss" scoped>
.checkbox-wrapper-2 {
  font-size: 10pt;
  display: flex;
  align-items: center;
  gap: 10px;
}
.checkbox-wrapper-2 .ikxBAC {
  appearance: none;
  background-color: #dfe1e4;
  border-radius: 72px;
  border-style: none;
  flex-shrink: 0;
  height: 20px;
  margin: 0;
  position: relative;
  width: 30px;
}

.checkbox-wrapper-2 .ikxBAC::before {
  bottom: -6px;
  content: '';
  left: -6px;
  position: absolute;
  right: -6px;
  top: -6px;
}

.checkbox-wrapper-2 .ikxBAC,
.checkbox-wrapper-2 .ikxBAC::after {
  transition: all 100ms ease-out;
}

.checkbox-wrapper-2 .ikxBAC::after {
  background-color: #fff;
  border-radius: 50%;
  content: '';
  height: 14px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 14px;
}

.checkbox-wrapper-2 input[type='checkbox'] {
  cursor: default;
}

.checkbox-wrapper-2 .ikxBAC:hover {
  background-color: #c9cbcd;
  transition-duration: 0s;
}

.checkbox-wrapper-2 .ikxBAC:checked {
  background-color: #34455A;
}

.checkbox-wrapper-2 .ikxBAC:checked::after {
  background-color: #fff;
  left: 13px;
}

.checkbox-wrapper-2 :focus:not(.focus-visible) {
  outline: 0;
}

.checkbox-wrapper-2 .ikxBAC:checked:hover {
  background-color: #003ca3d5;
}
</style>
