<template>
  <LoadingIcon v-if="isLoading" />
  <section v-else-if="!isLoading && categories.length" class="categories-grid-wrapper">
    <section class="categories-grid">
      <CategoryCardDataType
        v-for="category in categories"
        :key="category.id"
        :data="category"
        :icon="category.icon"
        @onSelectCategory="emits('onSelectCategory', category)"
      />
    </section>
    <button class="btn btn-ck button-download" @click="downloadCSVWithHealthKitData">
      Download CSV
    </button>
  </section>
  <div v-else>No data found</div>
</template>

<script lang="js" setup>
import { computed, onMounted, ref, inject } from 'vue';
import { getHealthKitData } from '../services/healthCategories.services';
import {
  HK_CATEGORIES,
  COLUMNS_CSV_WITH_HEALTH_KIT_DATA,
  HK_CATEGORIES_SLEEP_ANALYSIS,
} from '../utils/HealthKit';
import { generateCSVFile } from '@/common/utils/generateCSVFile';
import { formatDate } from '@/common/utils/formDate';
import CategoryCardDataType from '../molecules/CategoryCardDataType.vue';
import { toast } from 'vue3-toastify';
import LoadingIcon from '@/components/loading';

const emits = defineEmits(['onSelectCategory']);

const healthData = ref([]);
const isLoading = ref(false);

const collectedFormData = inject('collectedFormData');

function createDataTypeMap(categories) {
  const map = new Map();

  Object.entries(categories).forEach(([categoryName, categoryData]) => {
    categoryData.dataType.forEach((dataType) => {
      map.set(dataType.key, {
        categoryName,
        categoryLogo: categoryData.logo,
        dataType,
      });
    });
  });

  return map;
}

function addToCategoryMap(categoryMap, categoryName, categoryLogo, dataType) {
  if (!categoryMap.has(categoryName)) {
    categoryMap.set(categoryName, {
      name: categoryName,
      logo: categoryLogo,
      dataTypes: [],
    });
  }

  categoryMap.get(categoryName).dataTypes.push({
    key: dataType.key,
    name: dataType.name,
    color: dataType.color,
    chartType: dataType.typeChart,
  });
}

function extractUniqueDataTypeKeys(healthData) {
  return [...new Set(healthData.map((entry) => entry.code?.codeValue).filter(Boolean))];
}

const dataTypeMap = createDataTypeMap(HK_CATEGORIES);

const categories = computed(() => {
  if (!Array.isArray(healthData.value) || healthData.value.length === 0) {
    return [];
  }

  const uniqueDataTypeKeys = extractUniqueDataTypeKeys(healthData.value);
  const categoryMap = new Map();

  uniqueDataTypeKeys.forEach((dataTypeKey) => {
    const mapping = dataTypeMap.get(dataTypeKey);

    if (mapping) {
      const { categoryName, categoryLogo, dataType } = mapping;
      addToCategoryMap(categoryMap, categoryName, categoryLogo, dataType);
    }
  });

  return Array.from(categoryMap.values());
});

const formatCSVDataWithHealthKit = (data) => [
  COLUMNS_CSV_WITH_HEALTH_KIT_DATA,
  ...data.map(({ id, code, value, unit, startDate, device, endDate }) => {
    const name =
      Object.values(HK_CATEGORIES)
        .flatMap(({ dataType }) => dataType)
        .find(({ key }) => key === code.codeValue)?.name || '';
    const isSleepAnalysis = HK_CATEGORIES_SLEEP_ANALYSIS.includes(code.codeValue);
    let formattedDate = isSleepAnalysis
      ? `${formatDate(startDate)} - ${formatDate(endDate)}`
      : formatDate(startDate);
    const deviceForCSV = `${device?.display} ${device?.type}`;
    return [id, name, value.toFixed(2), unit, deviceForCSV, formattedDate];
  }),
];

const downloadCSVWithHealthKitData = async () => {
  const data = await getHealthKitData({
    studyId: collectedFormData.studyId,
    userId: collectedFormData.userId,
    withSort: true,
  });

  const csvData = formatCSVDataWithHealthKit(data);
  generateCSVFile(csvData, 'health_data');
};

onMounted(async () => {
  try {
    isLoading.value = true;
    healthData.value = await getHealthKitData({
      studyId: collectedFormData.studyId,
      userId: collectedFormData.userId,
    });
  } catch (error) {
    toast.error(error.message);
  } finally {
    isLoading.value = false;
  }
});
</script>

<style lang="scss" scoped>
.categories-grid {
  display: grid;
  gap: 20px 30px;
  grid-template-columns: repeat(2, minmax(300px, 1fr));
  justify-content: center;
  align-items: center;
}

.button-download {
  margin-top: 1rem;
}
</style>
