import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/auth/views/Login';
import StudiesList from '@/studies/views/Studies.vue';
import RegisterStudies from '@/studies/views/RegisterStudies';
import RegisterSurveys from '@/surveys/views/RegisterSurveys';
import PatientsList from '@/patients/views/PatientsListView';
import PatientData from '@/patients/views/PatientData';
import { useUserRoleStore } from '@/store/modules/user/useUserRoleStore';
import { auth } from '@/plugins/firebase/firebase';

export const ROUTES = {
  AUTH: {
    LOGIN: 'Login',
  },
  STUDY: {
    LIST: 'studies',
    PATIENTS: 'patients',
    PATIENT_STUDY: 'patientStudy',
    REGISTER: 'registerStudies',
  },
  SURVEY: {
    REGISTER: 'registerSurveys',
  },
  HOME: 'Home',
};

const authRoutes = [
  {
    path: '/login',
    name: ROUTES.AUTH.LOGIN,
    component: Login,
  },
];

const studyRoutes = [
  {
    path: '/studies',
    name: ROUTES.STUDY.LIST,
    component: StudiesList,
    meta: { requiresAuth: true },
  },
  {
    path: 'studies/:studyId',
    name: ROUTES.STUDY.PATIENTS,
    component: PatientsList,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: 'studies/:studyId/:patientId',
    name: ROUTES.STUDY.PATIENT_STUDY,
    component: PatientData,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/register-studies',
    name: ROUTES.STUDY.REGISTER,
    component: RegisterStudies,
    meta: { requiresAuth: true },
  },
];

const surveyRoutes = [
  {
    path: '/register-surveys',
    name: ROUTES.SURVEY.REGISTER,
    component: RegisterSurveys,
    meta: { requiresAuth: true },
  },
];

const mainRoutes = {
  path: '/',
  name: ROUTES.HOME,
  redirect: '/studies',
  component: () => import('@/common/layout/Main'),
  meta: { requiresAuth: true },
  children: [...studyRoutes, ...surveyRoutes],
};

const routes = [...authRoutes, mainRoutes];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const userRoleStore = useUserRoleStore();

  try {
    handleFirebaseAuth(to, next, userRoleStore);
  } catch {
    next({ name: ROUTES.AUTH.LOGIN });
  }
});

function handleFirebaseAuth(to, next, userRoleStore) {
  const requiresAuth = to.meta.requiresAuth || false;

  const unsubscribe = auth.onAuthStateChanged((user) => {
    try {
      if (user) {
        if (to.path === '/') {
          next({ name: ROUTES.HOME });
        } else if (to.name === ROUTES.AUTH.LOGIN) {
          next({ name: ROUTES.HOME });
        } else {
          userRoleStore.getUserRole();
          next();
        }
      } else {
        if (requiresAuth) {
          next({ name: ROUTES.AUTH.LOGIN });
        } else {
          next();
        }
      }
    } finally {
      unsubscribe();
    }
  });
}

export default router;
