<template>
  <div class="mt-5" v-if="loading">
    <loading-icon class="flex justify-center" size="10px" />
  </div>
  <div class="page" v-else>
    <h1 class="mb-5">Studies</h1>
    <div v-if="!studies.length">
      <h4>Without studies...</h4>
    </div>
    <div class="grid-studies" v-else>
      <small-card
        v-for="study in studies"
        :key="study"
        @onClick="handleSelectStudy(study)"
        :title="study.name || study.id"
        :image="HEALTHKIT_IMAGES.HEART_WITH_HAND"
        actionText="Go to study"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { toast } from 'vue3-toastify';
import { getStudies, getUsers } from '@/studies/services/studies';
import { HEALTHKIT_IMAGES } from '@/common/static_data/constants';
import loadingIcon from '@/components/loading';
import SmallCard from '@/patients/atoms/SmallCard';

const props = defineProps({
  handleSelectStudy: Function,
});

const loading = ref(false);
const studies = ref({});

onMounted(async () => {
  try {
    loading.value = true;
    const studiesData = await getStudies();
    const studiesWithUsers = await Promise.all(
      studiesData.map(async (study) => {
        const users = await getUsers({ studyId: study.id });
        return { ...study, hasUsers: users.length > 0 };
      })
    );
    studies.value = studiesWithUsers;
  } catch (error) {
    toast.error(error.message);
  } finally {
    loading.value = false;
  }
});
</script>

<style lang="scss" scoped>
.grid-studies {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
}
</style>
