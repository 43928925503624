<template>
  <section>
    <button
      :disabled="props.disabled"
      class="form-input w-100 addConsent-button"
      type="button"
      @click="toggleModalRegisterStudyConsent"
      required
    >
      Manage Study Consent
    </button>
    <modal
      title="Register Consent"
      :maxWidth="'1000px'"
      :showModal="isTogglingModal"
      @closeModal="toggleModalRegisterStudyConsent"
    >
      <template v-slot:content>
        <form class="form" @submit.prevent="handleSubmitRegister">
          <div class="form-group m-2">
            <md-editor
              class="markdown"
              v-model="consentRef"
              language="en-US"
              :toolbars-exclude="ToolBarExcludedOptions"
              :on-html-changed="onHtmlChanged"
            />
          </div>
          <div class="flex gap-3 mt-5 w-100">
            <button class="btn btn-ck m-auto" type="submit">
              Register Consent
            </button>
          </div>
        </form>
      </template>
    </modal>
  </section>
</template>

<script setup>
import { ref, watch } from 'vue';
import { toast } from 'vue3-toastify';
import { marked } from 'marked';
import { MdEditor } from 'md-editor-v3';
import DOMPurify from 'dompurify';
import Modal from '@/components/Modal';
import 'md-editor-v3/lib/style.css';

const props = defineProps({ consent: String, disabled: Boolean });
const emit = defineEmits(['addConsent']);

const isTogglingModal = ref(false);
const consentRef = ref('');

const ToolBarExcludedOptions = [
  'image',
  'save',
  'mermaid',
  'prettier',
  'catalog',
  'github',
  'codeRow',
  'code',
  'pageFullscreen',
  'fullscreen',
];

function toggleModalRegisterStudyConsent() {
  isTogglingModal.value = !isTogglingModal.value;
}

function onHtmlChanged(html) {
  return DOMPurify.sanitize(marked(html));
}

function handleSubmitRegister() {
  emit('addConsent', consentRef.value);
  toast.success('The consent has been added successfully');
  toggleModalRegisterStudyConsent();
}

watch(
  () => props.consent,
  (newConsent) => {
    consentRef.value = newConsent;
  }
);
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;

  .form-group {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;

    label {
      text-align: start;
      margin-bottom: 3px;
      font-size: 10pt;
    }

    a {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  button#b-form {
    margin-top: 1rem;
  }
}

.addConsent-button {
  &:not(:disabled):hover {
    background-color: lightgrey;
    border: 1px solid black;
  }
  &:disabled {
    cursor: default;
  }
}
</style>
