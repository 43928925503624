import request from '@/Rest';
import { TranslateHealthKitData } from '../translate/healthKitData.translate';

export const getHealthKitData = async ({ studyId = '', userId = '', withSort = false }) => {
  try {
    let statsRequest = request.GET(`users/${userId}/healthData/${studyId}/stats`);
    let sleepAnalysisRequest = request.GET(`users/${userId}/healthData/${studyId}/sleepAnalysis`);

    const [statsResponse, sleepAnalysisResponse] = await Promise.all([
      statsRequest.Execute(),
      sleepAnalysisRequest.Execute(),
    ]);

    const statsData =
      statsResponse?.docs?.map((data) =>
        TranslateHealthKitData.DomainFromDTO({
          id: data.id,
          ...data.data(),
        })
      ) || [];

    const sleepAnalysisData =
      sleepAnalysisResponse?.docs?.map((data) =>
        TranslateHealthKitData.DomainFromDTO({
          id: data.id,
          ...data.data(),
        })
      ) || [];

    let combinedData = [...statsData, ...sleepAnalysisData];

    if (withSort) {
      combinedData = combinedData.sort((a, b) => {
        const dateA = new Date(a.startDate);
        const dateB = new Date(b.startDate);
        return dateA - dateB;
      });
    }

    return combinedData;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getHealthKitDataByCategory = async ({
  studyId = '',
  userId = '',
  codeFilter = '',
  startDate = '',
  endDate = '',
  collectionType = 'stats',
}) => {
  try {
    let startQuery = request.GET(`users/${userId}/healthData/${studyId}/${collectionType}`);
    let endQuery = request.GET(`users/${userId}/healthData/${studyId}/${collectionType}`);

    if (startDate) {
      const formattedStartDate = new Date(startDate).toISOString();
      startQuery = startQuery.WHERE(['effectivePeriod.start', '>=', formattedStartDate]);
    }

    if (endDate) {
      const formattedEndDate = new Date(endDate).toISOString();
      endQuery = endQuery.WHERE(['effectivePeriod.end', '<=', formattedEndDate]);
    }

    const startResponse = await startQuery.Execute();
    const endResponse = await endQuery.Execute();

    const matchingResults = startResponse.docs.filter((data) =>
      endResponse.docs.some((item) => item.id === data.id)
    );

    const filteredResults = matchingResults.filter(
      (data) => data.data().code.coding[0]?.code === codeFilter
    );

    const adaptedResults =
      filteredResults.map((data) =>
        TranslateHealthKitData.DomainFromDTO({
          id: data.id,
          ...data.data(),
        })
      ) || [];

    return adaptedResults;
  } catch (error) {
    throw new Error(error.message);
  }
};
