<template>
  <LoadingIcon v-if="isLoading" />
  <section class="wrapper-activities" v-else-if="!isLoading && healthKitDataByDate.length">
    <activity-card
      v-for="(data, index) in healthKitDataByDate"
      :key="index"
      :id="data.id"
      :code="data.code"
      :value="data.value"
      :unit="data.unit"
      :startDate="data.startDate"
      :endDate="data.endDate"
      :logo="category?.logo"
      :colorTitle="data.code?.nameToShow?.color || '#000'"
      @cardSelected="emits('onSelectCategoryDetail', data.code)"
    />
  </section>
  <div v-else>No data found</div>
</template>

<script lang="js" setup>
import { onMounted, ref, inject } from 'vue';
import ActivityCard from '../molecules/ActivityCard.vue';
import { getHealthKitDataByCategory } from '../services/healthCategories.services';
import { HK_CATEGORIES, HK_CATEGORIES_SLEEP_ANALYSIS, TYPE_COLLECTION } from '../utils/HealthKit';
import { toast } from 'vue3-toastify';
import LoadingIcon from '@/components/loading';

const props = defineProps({
  category: {
    type: {
      name: String,
      dataType: Array,
      logo: String,
      chartType: String,
    },
    required: true,
  },
});

const emits = defineEmits(['onSelectCategoryDetail']);

const collectedFormData = inject('collectedFormData');

const healthKitDataByDate = ref([]);
const isLoading = ref(false);

onMounted(async () => {
  try {
    isLoading.value = true;

    const promises = props.category.dataTypes.map((dataType) =>
      getHealthKitDataByCategory({
        userId: collectedFormData.userId,
        studyId: collectedFormData.studyId,
        codeFilter: dataType.key,
        collectionType: HK_CATEGORIES_SLEEP_ANALYSIS.includes(dataType.key)
          ? TYPE_COLLECTION.SLEEP_ANALYSIS
          : TYPE_COLLECTION.STATS,
      })
    );

    const results = await Promise.allSettled(promises);

    const fulfilledResults = results
      .filter((result) => result.status === 'fulfilled' && result.value.length > 0)
      .map((result, index) => {
        const healthKitData = result.value;
        healthKitData.sort((a, b) => new Date(b.endDate) - new Date(a.endDate));

        const firstHealthKitData = healthKitData[healthKitData.length - 1];
        const lastHealthKitData = healthKitData[0];

        const { id, code, value, unit } = lastHealthKitData;

        return {
          id,
          code: {
            ...code,
            nameToShow: HK_CATEGORIES[props.category.name].dataType.find(
              (dt) => dt.key === lastHealthKitData.code.codeValue
            ),
          },
          value,
          unit,
          startDate: firstHealthKitData.startDate,
          endDate: lastHealthKitData.endDate,
        };
      });

    healthKitDataByDate.value = fulfilledResults;
  } catch (error) {
    toast.error(error.message);
  } finally {
    isLoading.value = false;
  }
});
</script>

<style lang="scss" scoped>
.wrapper-activities {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
}
</style>
