<template>
  <article>
    <section @click="emits('onSelectCategory')" class="card-category">
      <div class="flex align-center">
        <figure class="icon">
          <img :src="data.logo" :alt="data.name" />
        </figure>
        <p class="subtitle">{{ data.name }}</p>
      </div>
      <span class="arrow">›</span>
    </section>
  </article>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits(['onSelectCategory']);
</script>

<style lang="scss" scoped>
.card-category {
  padding: 1rem;
  background: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: $shadow-sm;

  .icon {
    width: 20px;
    height: 22px;
    margin-right: 1rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .subtitle {
    font-weight: 700;
    text-transform: capitalize;
  }

  .arrow {
    font-weight: 700;
  }
}
</style>
