import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import router from './router'
import store from './store'
import VueExcelXlsx from "vue-excel-xlsx";
import Vue3Toastify from 'vue3-toastify'
import 'vue3-toastify/dist/index.css';

const notificationOptions = {
  autoClose: 3000,
  closeButton: false,
  position: 'top-center',
  style: { width: 'fit-content' }
}

const pinia = createPinia();

createApp(App)
  .use(pinia)
  .use(Vue3Toastify, notificationOptions)
  .use(VueExcelXlsx)
  .use(store)
  .use(router)
  .mount('#app')
  