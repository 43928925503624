<template>
  <div>
    <apex-charts
      v-if="series"
      ref="chart"
      type="rangeBar"
      height="300"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script setup>
import { ref, computed, defineProps } from 'vue';
import ApexCharts from 'vue3-apexcharts';

const chart = ref(null);

const props = defineProps({
  series: {
    type: Array,
    required: true,
  },
  yMax: Number,
  yMin: Number,
  customTooltip: Function,
  titleFormatter: Function,
  horizontal: {
    type: Boolean,
    default: true,
  },
});

const chartOptions = computed(() => ({
  chart: {
    height: 350,
    type: 'rangeBar',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: true,
    },
  },
  xaxis: {
    type: 'datetime',
  },
  yaxis: {
    max: props.yMax,
    min: props.yMin,
  },
  legend: {
    position: 'bottom',
  },
}));
</script>
