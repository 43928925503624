import { db } from '@/plugins/firebase/firebase';
import request from '@/Rest';
import { TASKS_TYPES } from '@/common/constants';
import { SURVEYS_IDS } from '@/common/static_data/constants';
import {
  studiesPaths,
  surveysPaths,
  exercisesPaths,
  tasksPaths,
} from '@/common/static_data/api_routes';

const { v4: uuidv4 } = require('uuid');

export async function getSurveys() {
  try {
    const data = await request.GET(surveysPaths.list).Execute();
    const surveys = data.docs.map((element) => ({
      ...element.data(),
      id: element.id,
    }));
    const surveysWithoutOnBoarding = surveys.filter(
      (survey) => survey.id !== SURVEYS_IDS.ON_BOARDING_SURVEY
    );
    return surveysWithoutOnBoarding;
  } catch (error) {
    throw new Error('Error while fetching surveys');
  }
}

export async function getExercises() {
  try {
    const data = await request.GET(exercisesPaths.list).Execute();
    const exercises = data.docs.map((element) => ({
      ...element.data(),
      id: element.id,
    }));
    return exercises;
  } catch (error) {
    throw new Error('Error while fetching exercises');
  }
}

export async function getTasks() {
  try {
    const data = await request.GET(`${tasksPaths.list}`).Execute();
    const tasks = data.docs.map((task) => {
      const taskData = task.data();
      const studyId = taskData.studyReference?.id;
      const surveyId = taskData.surveyReference?.id;
      const exerciseId = taskData.exerciseReference?.id;

      return {
        id: task.id,
        studyId,
        surveyId,
        exerciseId,
        taskDelay: taskData.delay,
        taskFrequency: taskData.frequency,
        taskName: taskData.name,
        taskRepetitions: taskData.repetitions,
        taskType: taskData.type,
        taskValue: surveyId ?? exerciseId,
        taskSeriesNumber: taskData.seriesNumber ?? 1,
        taskOpenWindow: taskData.openWindow ?? 0,
      };
    });
    return tasks;
  } catch (error) {
    throw new Error('Error while fetching tasks');
  }
}

export async function registerTask(taskData, studyId) {
  try {
    const taskId = uuidv4();
    const seriesNumberToNumber = Number(taskData?.taskSeriesNumber ?? 1);
    const taskOpenWindow = Number(taskData?.taskOpenWindow ?? 1);
    const data = {
      id: taskId,
      type: taskData.taskType,
      name: taskData.taskName,
      delay: taskData.taskDelay,
      frequency: taskData.taskFrequency,
      repetitions: taskData.taskRepetitions,
      isActive: true,
      seriesNumber: seriesNumberToNumber,
      openWindow: taskOpenWindow,
    };

    data.studyReference = db.collection(studiesPaths.list).doc(studyId);

    if (taskData.taskType === TASKS_TYPES.SURVEY) {
      data.surveyReference = db.collection(surveysPaths.list).doc(taskData.taskValue);
    }

    if (taskData.taskType === TASKS_TYPES.EXERCISE) {
      data.exerciseReference = db.collection(exercisesPaths.list).doc(taskData.taskValue);
    }
    await request.POST(`${tasksPaths.list}/${taskId}`, { data }).Execute();
  } catch (error) {
    throw new Error('Error while registering study');
  }
}

export async function updateTask(taskData) {
  try {
    const seriesNumberToNumber = Number(taskData?.taskSeriesNumber ?? 1);
    const taskOpenWindow = Number(taskData?.taskOpenWindow ?? 1);
    const data = {
      type: taskData.taskType,
      name: taskData.taskName,
      delay: taskData.taskDelay,
      frequency: taskData.taskFrequency,
      repetitions: taskData.taskRepetitions,
      isActive: true,
      seriesNumber: seriesNumberToNumber,
      openWindow: taskOpenWindow,
    };

    if (taskData.taskType === TASKS_TYPES.SURVEY) {
      data.surveyReference = db.collection(surveysPaths.list).doc(taskData.taskValue);
    }

    if (taskData.taskType === TASKS_TYPES.EXERCISE) {
      data.exerciseReference = db.collection(exercisesPaths.list).doc(taskData.taskValue);
    }

    await request.PUT(`${tasksPaths.list}/${taskData.id}`, { data }).Execute();
  } catch (error) {
    throw new Error('Error while updating task');
  }
}

export async function deleteTask(taskId) {
  try {
    await request.DELETE(`${tasksPaths.list}/${taskId}`).Execute();
  } catch (error) {
    throw new Error('Error while deleting task');
  }
}
