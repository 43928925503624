<script setup>
import { computed, ref, watch } from 'vue';
import { MONTHS, WEEK_DAYS } from './calendarConstants';

const props = defineProps({
  label: String,
  showPopup: Boolean,
  calendar: Boolean,
  defaultDate: {
    type: Date,
    default: new Date(),
  },
});

const emit = defineEmits(['changeDate']);

const today = ref(props.defaultDate.getUTCDate());
const currentMonth = ref(props.defaultDate.getUTCMonth());
const currentYear = ref(props.defaultDate.getUTCFullYear());
const months = ref(MONTHS);
const days = computed(() => getDaysInMonth(currentYear.value, currentMonth.value));
const daysPerWeek = computed(() => getCalendarDaysOfWeek(days.value));
const years = computed(() => generateSelectableYears(currentYear.value));
const date = computed(() => `${Number(currentMonth.value) + 1}/${today.value}/${currentYear.value}`);

watch(() => props.defaultDate, () => {
  today.value = props.defaultDate.getUTCDate();
  currentMonth.value = props.defaultDate.getUTCMonth();
  currentYear.value = props.defaultDate.getUTCFullYear();
});

function getDaysInMonth(year, month) {
  const totalDays = new Date(year, month + 1, 0).getDate();
  return [...Array(totalDays).keys()].map(day => getDayOfWeek(year, month, day + 1));
}

function getDayOfWeek(year, month, day) {
  const calendarDay = new Date(year, month, day).getDay();
  return { day, calendarDay };
}

function generateSelectableYears(year) {
  const countYears = 10;
  const startYear = year - 3;
  return [...Array(countYears).keys()].map(index => index + startYear);
}

function getCalendarDaysOfWeek(calendarDays) {
  let calendar = [];
  const voidData = calendarDays[0].calendarDay;
  calendar = voidData > 0 ? [...Array(voidData - 1).fill(0), ...calendarDays] : [0, ...calendarDays];
  return calendar;
}

function selectedDay(day) {
  today.value = day;
  handleChangeDate();
}

function handleChangeDate() {
  const newDate = new Date(currentYear.value, currentMonth.value, today.value);
  emit('changeDate', newDate);
}
</script>

<template>
  <div class="date-picker">
    <label class="alt-datetime-wrapper">
      <span v-if="label" class="block">{{ label }}</span>
      <input autocomplete="off" class="alt-datetime-wrapper__input" placeholder="--/--/--" type="text" name="datetime" v-model="date" />
    </label>
    <div v-if="showPopup" class="alt-popup">
      <div v-if="!calendar" class="alt-date">
        <div class="alt-date-group">
          <label class="alt-date-group__label" for="days">
            <span>Day</span>
            <select @change="handleChangeDate" name="days" v-model="today" id="days">
              <option v-for="(day, index) in days" :key="index + 'alt'" :value="day.day">{{ day.day }}</option>
            </select>
          </label>
        </div>
        <div class="alt-date-group">
          <label class="alt-date-group__label" for="months">
            <span>Month</span>
            <select @change="handleChangeDate" name="month" v-model="currentMonth" id="months">
              <option v-for="month in months" :key="month.id" :value="month.id">{{ month.name }}</option>
            </select>
          </label>
        </div>
        <div class="alt-date-group">
          <label class="alt-date-group__label" for="years">
            <span>Year</span>
            <select @change="handleChangeDate" name="year" v-model="currentYear" id="years">
              <option v-for="(year, index) in years" :key="index + 'alt'" :value="year">{{ year }}</option>
            </select>
          </label>
        </div>
      </div>
      <div v-else class="alt-calendar">
        <div class="alt-calendar-header">
          <div>
            <select class="alt-calendar-header__months" @change="handleChangeDate" name="month" v-model="currentMonth" id="months">
              <option v-for="month in months" :key="month.id" :value="month.id">{{ month.name }}</option>
            </select>
            <select class="alt-calendar-header__years" @change="handleChangeDate" name="year" v-model="currentYear" id="years">
              <option v-for="(year, index) in years" :key="index + 'alt'" :value="year">{{ year }}</option>
            </select>
          </div>
        </div>
        <div class="alt-calendar-body">
          <ul class="alt-calendar-body__WEEK_DAYS">
            <li v-for="(day, index) in WEEK_DAYS" :key="day" :value="index + 1">{{ day }}</li>
          </ul>
          <div class="alt-calendar-body__days">
            <span @click="selectedDay(day.day)" :class="{ active: today === day.day }" class="calendar-day" v-for="(day, index) in daysPerWeek" :key="index" :value="day.day">{{ day.day }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.date-picker {
  position: relative;
}
.alt-popup {
  display: inline-block;
  padding: 1rem;
  background: white;
  border-radius: 3px;
  margin-top: 15px;
  position: absolute;
  top: 55px;
  left: 0;
  z-index: 2;
  &:before {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    top: -6px;
    content: "";
    height: 0;
    left: 30%;
    margin-left: -6px;
    position: absolute;
    width: 0;
  }
}
.alt-date {
  display: flex;
  gap: 10px;
  &-group {
    &__label {
      display: flex;
      border: solid 1px #f7f7f7;
      border-radius: 3px;
      span {
        background: #f7f7f7;
        padding: 0.3rem;
      }
      select {
        padding: 0.3rem;
        border: none;
      }
    }
  }
}
.alt-datetime-wrapper {
  &__input {
    padding: 0.5rem;
    border: solid 2px lightgrey;
    border-radius: 3px;
    font-size: 13pt;
    display: inline-block;
    &::placeholder {
      color: darkgrey;
      opacity: 1;
    }
  }
}
.alt-calendar {
  &-header {
    div {
      display: flex;
      gap: 10px;
      justify-content: center;
    }
    &__months,
    &__years {
      padding: 0.5rem;
      width: 100%;
      border: none;
      border-radius: 3px;
      outline: none;
      background: #f7f7f7;
    }
  }
  &-body {
    &__WEEK_DAYS {
      list-style: none;
      padding: 0.5rem 0;
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(7, 1fr);
      color: #90a4ae;
      li {
        min-width: 32px;
        text-align: center;
      }
    }
    &__days {
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(7, 1fr);
      .calendar-day {
        text-align: center;
        border-radius: 3px;
        cursor: pointer;
        padding: 0.2rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background: #f7f7f7;
        }
        &.active {
          background: crimson;
          color: white;
        }
      }
    }
  }
}
</style>
