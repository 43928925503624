export const formatDate = (date, { hour12 = true } = {}) => {
  if (!date) return '';

  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12,
  };

  return new Date(date).toLocaleDateString('en-US', options);
};
