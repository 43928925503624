<template>
  <div class="flex justify-center m-5">
    <alt-table
      :columns="columns"
      pagination
      :paginationOptions="paginationOptions"
      @onPagination="handlePagination"
    >
      <template #t-row>
        <tr v-for="(survey, index) in getPageItems" :key="index">
          <td>{{ index + 1 }}</td>
          <td>
            <h4 class="flex justify-center">
              {{ survey.date || 'NN' }}
            </h4>
          </td>
          <td>
            <h3 class="flex justify-center text-center">{{ survey.name }}</h3>
          </td>
          <td class="container-actions">
            <span v-if="selectedSurveyId === survey.id" @click="handleCloseDownload()">
              <vue-excel-xlsx
                class="btn-ck btn-csv"
                :data="data"
                :columns="columnsTo"
                :file-name="survey.date + `-${patientId}_Survey-answers`"
                :file-type="'csv'"
                :sheet-name="'sheetname'"
              >
                Download File
              </vue-excel-xlsx>
            </span>
            <span v-else @click="handleSelectSurvey(survey.id)" class="text-center btn-ck">
              Generate .CSV
            </span>
          </td>
        </tr>
      </template>
    </alt-table>
  </div>
</template>

<script setup>
import altTable from '@/components/tables/altTable';
import { computed, ref } from 'vue';
import { toast } from 'vue3-toastify';
import { useOptions } from '@/components/common/paginationOptions';

const props = defineProps({
  columns: Array,
  patientSurveysInformation: Array,
  patientName: String,
  patientId: String,
});

const columnsTo = ref([]);
const data = ref([]);
const selectedSurveyId = ref('');
const download = ref(false);

function handleCloseDownload() {
  download.value = false;
  selectedSurveyId.value = '';
}

function parseQuestions(questions) {
  return questions.reduce((acc, question) => {
    if (question.type === 'display') return acc;
    if (question.type === 'group') {
      const groupQuestions = parseQuestions(question.item);
      return { ...acc, ...groupQuestions };
    }
    acc[question.linkId] = question.text;
    return acc;
  }, {});
}

function getAnswerValues(answers) {
  if (answers.length === 1) {
    const [answer] = Object.values(answers[0]);
    return typeof answer === 'object' ? answer?.code ?? `${answer.value} ${answer.unit}` : answer;
  }
  return answers.map((a) => a.valueCoding.code).join(', ');
}

function parseAnswers(answers) {
  return answers
    .filter(({ answer }) => answer.length > 0)
    .map(({ linkId, answer }) => {
      return {
        linkId,
        answer: getAnswerValues(answer),
      };
    });
}

async function handleSelectSurvey(id) {
  selectedSurveyId.value = id;
  const selectedSurvey = props.patientSurveysInformation.find((survey) => survey.id === id);

  const surveyQuestions = parseQuestions(selectedSurvey.surveyQuestions?.item);

  const surveyAnswers = parseAnswers(selectedSurvey.surveyAnswers?.item);

  const CSVStructure = surveyAnswers.reduce(
    (acc, answer, index) => {
      if (surveyQuestions[answer.linkId]) {
        acc['columns'].push({
          label: surveyQuestions[answer.linkId],
          field: `${index + 1}`,
        });
        acc['answers'] = [{ ...acc['answers'][0], [`${index + 1}`]: answer.answer }];
        return acc;
      }
      return acc;
    },
    {
      columns: [{ label: 'Patient ID', field: 'id' }],
      answers: [
        {
          id: props.patientId,
        },
      ],
    }
  );
  const { columns, answers } = CSVStructure;

  columnsTo.value = columns;
  data.value = answers;
  download.value = true;
  toast.success('Survey data has been selected and now is able to download');
}

const { paginationOptions, handlePagination, limit, currentPage } = useOptions(
  props.patientSurveysInformation
);
const getPageItems = computed(() => {
  const items = props.patientSurveysInformation.toSorted(
    (exerciseA, exerciseB) => exerciseB.timestamp - exerciseA.timestamp
  );
  const lowerLimit = (currentPage.value - 1) * limit.value;
  const upperLimit = currentPage.value * limit.value;
  return items.slice(lowerLimit, upperLimit);
});
</script>

<style lang="scss">
h1 {
  margin-top: 25px;
  margin-bottom: 50px;

  span {
    color: #34455A;
  }
}

.survey {
  display: flex;
  justify-content: center;
}

.select {
  width: 100%;
}

.btn-ck {
  border-radius: 10px;
}

.container-actions {
  display: flex;
  justify-content: center;
}

.btn-csv {
  background: #73E3CA;
}
</style>
