<template>
  <div style="display: flex; flex-direction: row">
    <v-otp-input
      ref="otpInput"
      input-classes="otp-input"
      separator="-"
      inputType="letter-numeric"
      :num-inputs="6"
      v-model:value="bindValue"
      :should-auto-focus="true"
      :should-focus-order="true"
      @on-complete="handleOnComplete"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';
import VOtpInput from 'vue3-otp-input';

const emits = defineEmits(['updateValue']);

const otpInput = ref(null);

const handleOnComplete = (value) => {
  emits('updateValue', value);
};
</script>

<style>
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

.otp-input.is-complete {
  border: 1px solid #34455A;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.otp-input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}
</style>
