import { marked } from 'marked';
import DOMPurify from 'dompurify';

export const TranslateStudyUsers = {
  DomainFromDTO({
    id,
    enrollmentTimestamp = [],
    isActive = true,
    isEnrollmentSurveyComplete = false,
    name = '',
  }) {
    return {
      id,
      name,
      enrollmentTimestamp,
      isActive,
      isEnrollmentSurveyComplete,
    };
  },
};

export const TranslateStudies = {
  DomainFromDTO({
    studyId,
    studyName,
    studyDuration,
    studyConsent,
    participantHealthData,
    version = 1,
  }) {
    const consent = DOMPurify.sanitize(marked(studyConsent));
    return {
      idNumber: studyId,
      name: studyName,
      duration: studyDuration,
      consent,
      isActive: true,
      participantHealthData,
      version,
    };
  },
};

export const TranslateCollectHealthData = {
  DTOFromDomain({ text = '', value = '', isActive = false }) {
    return {
      label: text,
      value,
      isActive,
    };
  },
};
