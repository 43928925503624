<template>
  <modal
    title="Register Group Item"
    :maxWidth="'450px'"
    :showModal="isTogglingGroupItemsModal"
    @closeModal="$emit('toggleGroupItemsModal')"
  >
    <template v-slot:content>
      <form class="form" @submit.prevent="handleAddGroupItem">
        <section class="form-group m-2">
          <label for="groupItemType" class="flex text-muted mt-3"> Item Type </label>
          <select class="w-100 form-select mt-1" id="groupItemType" v-model="item.type" required>
            <option v-for="itemType in questionnaireItemTypesWithoutGroup" :value="itemType">
              {{ capitalize(itemType) }}
            </option>
          </select>

          <div v-if="item.type === 'display'" class="mt-3">
            <label for="textGroupItem" class="flex text-muted">Text</label>
            <input
              class="w-100 form-input mt-1"
              type="text"
              id="textGroupItem"
              v-model="item.value"
              required
            />
          </div>
          <div v-else class="mt-3">
            <label for="questionGroupItem" class="flex text-muted"> Question </label>
            <input
              class="w-100 form-input mt-1"
              type="text"
              id="questionGroupItem"
              placeholder="Question text"
              v-model="item.value"
              required
            />
          </div>
          <SwitchInput v-model="item.required" label="Answer Required:" />
        </section>

        <section
          v-if="questionnaireItemsWithOptions.includes(item.type)"
          class="form-group m-2"
          required
        >
          <label for="answerOptions" class="flex text-muted"> Answer Options (Min. 2) </label>
          <button
            class="form-input w-100 mt-1 addButton"
            type="button"
            id="answerOptions"
            @click="toggleAddGroupOptionsModal"
          >
            Add New Answer Option <b>+</b>
          </button>
        </section>

        <ul v-if="item.options?.length" class="options-list h-150">
          <li v-for="(option, index) in item.options" :key="`option-${index}-${option}`">
            <div class="ml-2">
              <span class="flex justify-center mb-2"> Option {{ index + 1 }} </span>
              <p class="break-words-smaller">{{ option?.display ?? option }}</p>
            </div>
            <div>
              <button
                type="button"
                class="btn btn-ck delete-button fill-danger"
                @click="handleRemoveGroupOption(index)"
              >
                Delete
              </button>
            </div>
          </li>
        </ul>

        <div class="flex gap-3 mt-5 w-100">
          <button class="btn btn-ck m-auto" type="submit">Add Group Item</button>
        </div>
      </form>
    </template>
  </modal>

  <register-group-item-options
    :item="item"
    :isTogglingGroupOptionsModal="isTogglingGroupOptionsModal"
    @add-group-item-option="handleAddGroupItemOption"
    @toggle-add-group-options-modal="toggleAddGroupOptionsModal"
  />
</template>

<script setup>
import { ref } from 'vue';
import { toast } from 'vue3-toastify';

import {
  capitalize,
  questionnaireItemTypesWithoutGroup,
  questionnaireItemsWithOptions,
  isCodingTypeElementWithAtLeastTwoOptions,
} from '@/surveys/utils';

import Modal from '@/components/Modal';
import RegisterGroupItemOptions from '@/surveys/atoms/RegisterGroupItemOptions';
import SwitchInput from '@/components/SwitchInput.vue';

const props = defineProps({
  item: {
    type: Object,
    default: {
      value: '',
      type: 'text',
      options: [],
    },
  },
  groupItems: Array,
  isTogglingGroupItemsModal: Boolean,
});

const emit = defineEmits([
  'toggleGroupItemsModal',
  'addNewGroupItem',
  'resetNewGroupItem',
  'removeGroupOption',
  'addGroupItemOption',
]);

const isTogglingGroupOptionsModal = ref(false);

function toggleAddGroupOptionsModal() {
  isTogglingGroupOptionsModal.value = !isTogglingGroupOptionsModal.value;
}

const handleAddGroupItemOption = (groupOption) => {
  emit('addGroupItemOption', groupOption);
};

const handleRemoveGroupOption = (index) => emit('removeGroupOption', index);

function handleAddGroupItem() {
  try {
    if (isCodingTypeElementWithAtLeastTwoOptions(props.item))
      throw new Error('Add at least two options to continue');

    emit('addNewGroupItem', props.item);
    emit('resetNewGroupItem');
    emit('toggleGroupItemsModal');
    toast.success('Group item added successfully');
  } catch (error) {
    toast.error(error.message);
  }
}
</script>

<style lang="scss" scoped>
.delete-button {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #fff;
  border: none;
  margin-right: 5px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}
</style>
